.formContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.passwordRecoveryText {
  font-weight: 100;
  font-size: 14px;
}

.signUpText {
  text-align: center;
}
