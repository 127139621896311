.btnBaseFont {
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.btnLarge {
  height: 48px;
  padding: 12px 16px;
}

.btnMedium {
  padding: 8px 16px;
}

.btnSmall {
  padding: 8px 16px;
  font-family: Afacad;
  font-size: 14px;
}

.btnBaseContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border: 0px;
  cursor: pointer;
}

.btnBlackContainer {
  color: var(--text-white, #FFF);

  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-surface_high_em, #292929);
}
.btnBlackContainer:hover {
  background: var(--surface-surface_1, #1C1C1C);
}
.btnBlackContainer:disabled {
  cursor: not-allowed;
  background-color: var(--surface-surface_2, #3F3F3F);
  border: 0;
}

.btnGrayContainer {
  color: var(--text-white, #0C0C0C);

  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-surface_1, #F7F7F6);
}
.btnGrayContainer:hover {
  background: var(--surface-surface_0, #ededed);
}
.btnGrayContainer:disabled {
  cursor: not-allowed;
  color: var(--text-white, #767676);
  background-color: var(--surface-surface_2, #F7F7F6);
  border: 0;
}

.btnSuccessContainer {
  color: var(--text-white, #0E813C);

  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-surface_1, #DAFBE7);
}
.btnSuccessContainer:hover {
  background: var(--surface-surface_0, #CFF9D9);
}
.btnSuccessContainer:disabled {
  cursor: not-allowed;
  color: var(--text-white, #767676);
  background-color: var(--surface-surface_2, #F7F7F6);
  border: 0;
}

.btnErrorContainer {
  color: var(--text-white, #ffffff);

  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-surface_1, #FA667F);
}
.btnErrorContainer:hover {
  background: var(--surface-surface_0, #F74D6A);
}
.btnErrorContainer:disabled {
  cursor: not-allowed;
  color: var(--text-white, #767676);
  background-color: var(--surface-surface_2, #F7F7F6);
  border: 0;
}

.fullWidth {
  width: 100%;
}
