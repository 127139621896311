
    @apply --marketplaceButtonStyles;

    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      background-color: var(--marketplaceColorDark);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
    }
  