.root {
  width: fit-content;
  white-space: nowrap;
  padding: 2px 10px;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
}

.success {
  color: #0ed065;
  background-color: #e3ffee;
}

.warning {
  color: #939310;
  background-color: #f8f8b5;
}

.error {
  color: #D7332B;
  background-color: #FCD7D6;
}

.default {
  color: #666471;
  background-color: #f3f3f4;
}
