.base {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70vh;
  border-top: 1px solid #DDD;
}

.left {
  min-width: 218px;
  width: 20%;
  background-color: #FFF;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.right {
  flex: 1 0 0;
  display: flex;
}

.chatRoomContainer {
  display: flex;
  padding: var(--spacing-3, 16px) var(--spacing-4, 24px);
  align-items: center;
  justify-content: start;
  gap: 8px;
  cursor: pointer;
}

.grayBackground {
  background: var(--color-gray-50, #F6F6F6);
}

.grayBackground:hover {
  background: var(--color-gray-100, #EAEAEA);
}

.avatarContainer {
  width: 48px;
  height: 48px;
  min-height: 48px;
  min-width: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.creatorContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.name {
  color: var(--typo-primary, #1C1C1C);
  text-align: start;

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.chatPreview {
  color: var(--typo-secondary, #656565);
  text-align: center;

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.chatContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
}
