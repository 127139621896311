.pagination {
  padding: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;
}

.paginationPrev {
  width: 50px;
  padding: 8px 16px;
  border: 1px solid #ececed;
  border-radius: 64px;
  background-color: #ffffff;
  &:disabled {
    background-color: #ffffff;
  }
}

.paginationNumberWrapper {
  display: flex;
  gap: 5px;
}

.paginationCurrentPage {
  padding: 8px 16px;
  background-color: #292929;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  font-weight: 700;
}

.paginationPages {
  padding: 8px 16px;
  border: none;
  border-radius: 12px;
  color: #494656;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}

.paginationNext {
  width: 50px;
  padding: 8px 16px;
  border: 1px solid #ececed;
  border-radius: 64px;
  background-color: #ffffff;
  &:disabled {
    background-color: #ffffff;
  }
}
