@import '../../styles/marketplace.css';

.root {
}

.newPasswordSection {
  /* margin-top: 16px; */
  margin-bottom: 24px;
  padding-top: 6px;

  @media (--viewportMedium) {
    /* margin-bottom: 56px; */
    padding-top: 0;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  margin-top: 44px;

  /* @media (--viewportMedium) {
    margin-top: 96px;
  } */
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.submitButton {
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #1c1c1c;
  cursor: pointer;
  color: #ffffff;
  background-color: #0f69e0;
  width: 100%;
  border: none;

  &:disabled {
    background-color: #f3f3f3;
    color: #cccccc;
  }
}

.password {
  margin-top: 16px;
}

.inputContainer {
  position: relative;
  margin-bottom: 24px;
}

.icon {
  position: absolute;
  top: 45px;
  right: 10px;
  cursor: pointer;
}
