.base {
  position: fixed; /* Sticks the overlay to the viewport */
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999;
}

.centerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text  {
  color: white;
  font-size: 20px;
  font-family: Afacad;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
}
