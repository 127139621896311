@import '../../styles/marketplace.css';
:root {
  --EditListingBackgroundAndExperienceForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}
.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.error {
  color: var(--failColor);
}

.isInfoCorrect,
.consentPayThroughProlead,
.consentProleadsPolicy,
.consentStripeAgreement,
.consentGrantProleadLicense {
  @apply --EditListingBackgroundAndExperienceForm_formMargins;
}

.grantProleadDiscretion {
  margin-top: 60px;
}

.submitButtonContainer {
  margin-top: 60px;
}

.submitButton {
  margin-top: 20px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
  }
}

.question {
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 20px;
}

.answer {
  margin: 0;
}

.identityImage {
  width: 200px;
  margin-right: 10px;
}

.photos {
  margin-top: 20px;
}

.mapTitle {
  color: var(--matterColorDark);
}
