@import '../../../styles/customMediaQueries.css';

.root {
  /* Clearfix */
  composes: clearfix from global;
}

div,
p {
  font-size: 16px;
}

.messageItem {
  margin: 0 24px 20px 24px;

  /* Clearfix */
  composes: clearfix from global;

  @media (--viewportMedium) {
    margin-bottom: 17px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  /* Word break: break-all is causing it to break by characters instead of by words when space isn't available
    So.. i switch to word-wrap: break-word
  */
  /* word-break: break-all; */
  word-break: break-word;

  max-width: 433px;
}

.message {
  display: flex;
  flex-direction: row;
}

.ownMessage {
  float: right;
}

.avatar {
  flex-shrink: 0;
  margin: 0px 12px 0 0;

  @media (--viewportMedium) {
    margin: 0px 12px 0 0;
  }
}

.ownMessageContentWrapper {
  composes: clearfix from global;
}

.messageContent,
.ownMessageContent {
  composes: textSmall from global;

  display: inline-block;
  margin: 0;
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);
  background-color: #fff;

  @media (--viewportMedium) {
    padding: 8px 14px 8px 14px;
    margin: 0;
  }
}

.messageContent {
  flex: 1;
}

.ownMessageContent {
  float: right;
  position: relative;
}

.customerInitialMessage {
  width: 100%;
  word-break: break-word;
}

.messageDate,
.ownMessageDate {
  composes: marketplaceMessageDateFontStyles from global;

  margin: 11px 0 0 0;
  color: var(--colorGrey300);

  @media (--viewportMedium) {
    margin: 0 0 0 0;
  }
}

.ownMessageDate {
  text-align: right;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.transitionItemContainer {
  margin-bottom: 18px;
  width: 100%;
  display: flex;
}

.transitionItemContainer:first-child {
  justify-content: flex-end;
}

.transitionItem {
  margin: 0 24px 18px 24px;

  /* Clearfix */
  composes: clearfix from global;

  @media (--viewportMedium) {
    margin-bottom: 16px;
    max-width: 400px;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 12px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContent {
  composes: marketplaceTxTransitionFontStyles from global;
  margin: 0;
}

.transitionDate {
  composes: marketplaceMessageDateFontStyles from global;
  color: var(--colorGrey300);
  margin: 7px 0 0 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.transitionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.reviewContent {
  composes: h4 from global;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.orderMeaasge {
  margin-top: 8px;
  color: #100c20;
  line-height: 24px;
}

.buttonsContainer {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.orderControlButton {
  background-color: #f3f3f4;
  padding: 8px 16px;
  border-radius: 48px;
  color: #100c20;
}

.orderControlButton:first-child {
  width: 40px;
  padding: 0;
  padding: 0 12px;
  color: transparent;
}

.orderControlButton:last-child {
  background-color: var(--marketplaceColor);
  color: #fff;
}

.link {
  color: #494656;
}

.subtitle {
  margin: 16px 0 8px 0;
  color: #100c20;
  font-weight: 700;
}

.feedComponentContent {
  white-space: pre-wrap;
}
