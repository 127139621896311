.root {
  width: 99vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
}

.left {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bannerImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.right {
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: 100%;
}

.logoImage {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 50%;
}

.contentContainer {
  margin-top: 24px;
}

.title {
  margin-top: 16px;

  font-family: 'Unbounded';
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  color: #1c1c1c;
  white-space: wrap;
  word-break: break-all;
}

.description {
  margin-top: 16px;

  font-family: 'Afacad';
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #0c0c0c;
  white-space: pre-wrap;
  word-break: break-all;
}

.sectionText {
  font-family: 'Unbounded';
  font-size: 26px;
  font-weight: 700;
  line-height: 38px;
  color: #1c1c1c;
  white-space: wrap;
  word-break: break-all;

  margin-bottom: 10px;
  margin-top: 20px;
}

.formContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.label {
  font-weight: 700;
  font-size: 20px;
  font-family: 'Afacad';
  color: #0c0c0c;
  margin-bottom: 8px;
}

.gridItem2 {
  grid-column: 1 / 3;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btnText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.rootApplied {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.appliedContainer {
  padding: 50px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.textCenter {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.poweredContainer {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.poweredText {
  display: flex;
  flex-wrap: nowrap;
  width: min-content;
  background-color: #f2f2f1;
  padding: 10px;
  border-radius: 8px;
  white-space: nowrap;
}

.logoIconContainer {
  display: flex;
  flex-wrap: nowrap;
}

.logoNameIcon {
  transition: 0.3s;
  padding-left: -4px;
  height: 20px;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  text-decoration: none;
}

.appliedSubtitle {
  text-align: center;
}
