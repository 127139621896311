
    font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -1px;
    
    margin-top: 18px;
    margin-bottom: 18px;

    @media (--viewportMedium) {
      font-size: 48px;
      font-weight: var(--fontWeightSemiBold);
      line-height: 56px;
      letter-spacing: -2px;
      
      margin-top: 24px;
      margin-bottom: 24px;
    }
  