.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  border-radius: var(--radius-border_radius_20, 20px);
  background: #FFF;
}

.imageContainer {
  width: 100%;
  min-height: 200px;
  background: var(--surface-disabled_high_em, #A4A4A2);
}

.contentContainer {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headerContainer {
  flex: 1 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.name {
  color: var(--text-high_em, #0C0C0C);

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.24px;
}

.subhead {
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.basicInfoContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
}

.basicInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.basicInfoLabel {
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.basicInfoValue {
  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.descriptionLabel {
  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
  margin-bottom: 8px;
}

.descriptionValue {
  width: 100%;
  flex-grow: 1;
  color: var(--text-med_em, #434343);

  white-space: pre-wrap;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-align: left;
}

.deliverablesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.deliverablesItem {
  width: 100%;
  display: flex;
  align-items: start;
  gap: 16px;
}

.iconContainer {
  flex-shrink: 1;
  width: 25px;
}

.checkIcon {
  width: 25px;
  height: 25px;
}

.lineIcon {
  margin: 0px 8px;
}

.offerImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.campaignStatusChip {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: var(--radius-border_radius_6, 6px);
  background: var(--surface-success_accent_1, #DAFBE7);

  color: var(--color-success-800, #0E813C);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
