.card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clientContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.clientName {
  font-family: Afacad;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0c0c0c;
}

.postTime {
  font-weight: 400;
}

.clientAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.title {
  padding-top: 8px;
  font-family: Unbounded;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #0c0c0c;
  white-space: pre-wrap;
}

.contentChip {
  padding-top: 8px;
  display: flex;
  gap: 8px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currency {
  font-family: Afacad;
  font-weight: 700;
  line-height: 24px;
  font-size: 16px;
  color: #0c0c0c;
}

.location {
  font-weight: 400;
}

.chipContainer {
  padding-bottom: 8px;
}
