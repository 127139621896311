
    @apply --marketplaceButtonStyles;

    background-color: var(--matterColorLight);
    color: var(--matterColor);

    border-width: 1px;
    border-style: solid;
    border-color: var(--marketplaceColor);

    &:hover,
    &:focus {
      background-color: var(--matterColorLight);
      border-color: var(--marketplaceColor);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      border-color: var(--matterColorNegative);
    }
  