@import '../../../styles/marketplace.css';

.base {
  display: flex;
  justify-content: center;
  align-items: center;
}

.root {
  display: flex;
  flex-direction: column-reverse;

  padding: 20px 24px 48px 24px;
  width: 100%;
  text-align: center;
  gap: 32px;

  @media (--viewportLarge) {
    text-align: left;
    flex-direction: row;
    gap: 60px;
    max-width: 1280px;
    padding: 48px 60px 60px 60px;
  }
}

.content_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header_title_container {
  align-self: stretch;
}

.title {
  color: var(--text-high_em, #fff);
  font-family: Unbounded;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.32px;
  margin-bottom: 16px;

  @media (--viewportLarge) {
    letter-spacing: -0.44px;
    font-size: 44px;
    line-height: 56px;
    margin-bottom: 20px;
  }
}

.subtitle {
  color: var(--text-white, #fff);
  font-family: Afacad;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.2px;
  width: 100%;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 32px;
  }
}

.textContent {
  align-self: stretch;
  color: var(--text-high_em, #fff);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    font-size: 18px;
    margin-bottom: 32px;
    line-height: 30px;
  }
}

.bottom_section {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  @media (--viewportLarge) {
    display: flex;
  }
}

.bottom_text {
  color: var(--text-high_em, #fff);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.bottom_button {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: var(--radius-border_radius_100, 100px);
  border: 1px solid var(--outline-white, #fff);
  background: var(--surface-surface_0, #292929);

  color: var(--text-high_em, #fff);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (--viewportLarge) {
    justify-content: flex-start;
    width: 100%;
  }
}

.btn_join {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 48px;
  padding: 12px 16px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--text-white, #fff);
  border: 0;

  color: var(--color-dark-700, #292929);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  cursor: pointer;

  @media (--viewportLarge) {
    flex-grow: 0;
    width: fit-content;
    white-space: nowrap;
  }
}

.btn_join:hover {
  background-color: #d8d8d2;
}

.btn_learnmore {
  height: 48px;
  padding: 12px 16px;
  flex: 1;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 1px solid var(--surface-white, #fff);

  color: var(--surface-white, #fff);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportLarge) {
    flex-grow: 0;
    width: 300px;
    white-space: nowrap;
  }
}

.image_container {
  border-radius: 48px;
  overflow: hidden;

  @media (min-width: 568px) {
    border-radius: 92px;
  }

  @media (--viewportLarge) {
    border-radius: 48px;
    max-width: 500px;
  }
}

.image_container > img {
  width: 100%;
  height: 100%;
}

.heroImage {
  display: block;
  width: 100%;
}
