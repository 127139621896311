@import '../../styles/marketplace.css';

.instagramLogo {
  width: 32px;
  height: 32px;
  margin-right: 4px;
}

.title {
  color: #100c20;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.2px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

}

.subtitle {
  color: #100c20;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 16px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
}

.card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: #f8f8f8;
  color: #100c20;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background: #f8f8f8;
  margin-bottom: 16px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.imagePlaceholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #e0e0e0;
  padding: 16px;
  color: #ffffff;
}

.username {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.stats {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.helpText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #7a7a7a;
  margin-top: 32px;
  display: flex; 
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 8px;
}

.icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 12px;
  color: var(--marketplaceColor);
}

.connectButton {
  display: flex;
  align-items: center;
  background-color: var(--marketplaceColor);
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  padding:8px 16px;
  border-radius: 64px;
  margin-top:24px;

  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }
}
