@import '../../styles/marketplace.css';

.root {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 1000;

  /* Dimensions */
  width: 100vw;
  padding: 5px 10px;

  /* Layout */
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 16px;

  /* Colors */
  /* background-color: #313131; */
  background-color: #FFF;
  color: #434343;

  border-radius: var(--radius-border_radius_16, 16px);

  /* Shadow */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  /* Cover Topbar on desktop */
  @media (--viewportMedium) {
    width: 440px;
    bottom: 40px;
    right: 40px;
    padding: 24px 20px;
    flex-direction: column;
    align-items: start;
  }
}

.title {
  margin-bottom: 4px;

  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
}

.message {
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.cookieLink {
  color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }
}

.link {
  /* color: var(--matterColorLight); */
  color: var(--text-med_em, #434343);
}

.continueBtn {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Dimensions */
  padding: 3px 12px 3px 12px;
  margin-left: 24px;

  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColorDark);
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin: 0 24px;
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.acceptBtn {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 0px;
  background: var(--surface-surface_high_em, #292929);

  color: var(--text-white, #FFF);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */

  cursor: pointer;
}

.rejectBtn {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-surface_1, #F7F7F6);
  border: 0px;

  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */

  cursor: pointer;
}
