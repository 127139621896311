@import '../../styles/marketplace.css';

.wrapperMain {
  margin: 0 auto;
  padding: 0px 0px 100px 0px;
  background-color: #f8f7f5;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.title {
  color: var(--text-high_em, #0C0C0C);

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  letter-spacing: -0.24px;

  padding: 32px 32px 0px 32px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 8px 18px;

  margin: 12px 32px 0px 32px;
}

.sortContainer {
  margin: 20px 32px 0px 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}

.inputSelect {
  display: block;
  min-width: 180px;
  height: 40px;
  padding: 0px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  border: 1px solid var(--outline-low_em, #EEEEED);
  background-color: white;

  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.searchContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
}

.searchButtonContainer {
  display: flex;
  align-items: end;
  justify-content: end;
}

.buttonSearchContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.contentContainer {
  margin: 8px 32px 0px 32px;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: max-content;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 12px;
}

.table {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  border-collapse: collapse;
  overflow: hidden;
}

.table th {
  padding: 16px !important;
  color: #8c8a94;
  font-weight: 400;
  text-align: left;
}

.table td {
  padding: 16px;
  background-color: #ffffff;
  text-align: left;
  border-top: solid #f3f3f4 1px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0;
}

.switchInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switchInput:checked + .slider {
  background-color: #0C0C0C;
}

.switchInput:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switchInput:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* campaign dialog */
.campaignListContainer {
  margin: 12px 0px;
  width: 60vw;
  max-width: 60vw;
  max-height: 60vh;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  padding: 4px 10px 0px 0px;
}

.campaignCardWrapper {
  border-radius: 16px;
  border: 1px solid #d514d9;
}

.paginationContainer {
  padding-bottom: 100px;
}

.footerContainer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  min-height: 80px;
  background-color: #ffffff;
  border-top: 1px solid #f3f3f4;

  padding: 0px 32px 0px 32px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.inviteToCampaignButtonContainer {
  max-width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.emailFormContainer {
  margin: 12px 0px 12px 0px;
  width: 60vw;
  max-width: 60vw;
  max-height: 60vh;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.campaignSelectorFormContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.flexGrow {
  flex-grow: 1;
}
