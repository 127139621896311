@import '../../../../../styles/marketplace.css';

.wrapper {
  display: flex;

  justify-content: space-between;
  flex-direction: column;
  max-width: 1160px;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.content {
  margin-top: 32px;
  max-width: auto;
  @media (--viewportLarge) {
    margin-top: 48px;
    max-width: 400px;
  }
}

.imageContainer {
  order: -1;
  text-align: center;

  @media (--viewportLarge) {
    order: 0;
  }
}

.image {
  max-width: 100%;

  @media (--viewportLarge) {
    max-width: 700px;
  }
}

.title {
  color: var(--text-high_em, #fff);

  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.description {
  margin-top: 8px;

  color: #cccccc;
  font-size: 16px;
  font-weight: 400;

  @media (--viewportLarge) {
    margin-top: 16px;

    font-size: 18px;
  }
}

.btn_join {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 48px;
  padding: 12px 16px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--text-white, #fff);
  border: 0;

  color: var(--color-dark-700, #292929);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;

  @media (--viewportLarge) {
    flex-grow: 0;
    width: fit-content;
    white-space: nowrap;
  }

  cursor: pointer;
  &:hover {
    background-color: #d8d8d2;
  }
}

.btnWhiteOutline {
  height: 48px;
  padding: 12px 16px;
  flex: 1;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 1px solid var(--surface-white, #fff);

  color: var(--surface-white, #fff);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportLarge) {
    flex-grow: 0;
    width: 300px;
    white-space: nowrap;
  }
}

.btnContainer {
  margin-top: 32px;

  display: flex;
  gap: 16px;
  align-items: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputText {
  height: 40px;
  padding: 0px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  border: 1px solid var(--outline-low_em, #EEEEED);
  background: var(--surface-surface_0, #FFF);

  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.inputSelect {
  display: flex;
  height: 40px;
  padding: 0px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  border: 1px solid var(--outline-low_em, #EEEEED);

  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.errorMessage {
  margin-bottom: 8px;
  color: var(--typo-primary, #f35454);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.grid_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.buttonTextWithLoading {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
}
