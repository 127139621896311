.switcher {
  display: inline-flex;
  background-color: #eaeae8;
  color: #100c20;
  width: fit-content;
}

.switcherFullWidth {
  width: 100%;
  justify-content: space-around;
}

.switcher.xs {
  font-size: 12px;
  padding: 2px;
  gap: 8px;
  border-radius: 8px;
}

.switcher.sm {
  font-size: 16px;
  padding: 4px;
  gap: 8px;
  border-radius: 12px;
}

.switcher.md {
  font-size: 14px;
  padding: 4px;
  gap: 16px;
  border-radius: 16px;
}

.switcher.lg {
  font-size: 16px;
  padding: 4px;
  gap: 16px;
  border-radius: 16px;
}

.switcherButton {
  cursor: pointer;
  color: #8c8a94;
  width: fit-content;
  text-align: center;

  /* Make text vertically center on Mobile Screen */
  display: flex;
  justify-content: center;
  align-items: center;
}

.switcherButtonFullWidth {
  width: 100%;
}

.switcherButton.xs {
  padding: 2px;
  border-radius: 6px;
}

.switcherButton.sm {
  padding: 8px;
  border-radius: 8px;
}

.switcherButton.md {
  padding: 8px 12px;
  border-radius: 12px;
}

.switcherButton.lg {
  padding: 12px 16px;
  border-radius: 12px;
}

.active {
  background: #ffffff;
  color: #100c20;
  font-weight: 700;
}

.additional {
  display: flex;
  height: 20px;
  padding: 0px var(--spacing-spacing-xs, 6px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: var(--radius-border_radius_4, 4px);
  background: var(--surface-surface_1, #F7F7F6);

  color: var(--text-low_em, #828282);
  text-align: center;

  /* font_primary/body 2/regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  margin-left: 8px;
}
