.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  padding: 12px 0px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 12px;
}

.profileContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-bottom: 8px;
}

.profileGrid {
  flex: 1 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.profileImageContainer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f2f2f1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

strong {
  font-weight: 700;
  font-size: 18px;
}

.chatLoadingContainer {
  flex: 1 0 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  color: #828282;
}

.textRight {
  text-align: end;
}

.inputContainer {
  margin-top: 8px;
  border-top: 1px solid #f2f2f1;
  padding-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.flexGrow {
  flex: 1 0 0;
}

.errorMessage {
  color: red;
  font-size: 12px;
}
