.table {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  border-collapse: collapse;
  overflow: hidden;
}

.table th {
  padding: 16px !important;
  color: #8c8a94;
  font-weight: 400;
  text-align: left;
}

.table td {
  padding: 16px;
  background-color: #ffffff;
  text-align: left;
  border-top: solid #f3f3f4 1px;
}

.table th:nth-child(4) {
  text-align: center;
}

.table th:nth-child(5) {
  text-align: center;
}

.creatorContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.creatorAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.firstRow {
  padding: 0px !important;
}

.socialMediaContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.socialMediaIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.actionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.openChatLink {
  color: var(--text-primary_em, #7632D4);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  cursor: pointer;
  padding: 8px;
}

.openChatLink:hover {
  text-decoration: underline;
}

.statusText {
  color: var(--text-low_em, #828282);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionButton {
  display: flex;
  height: 32px;
  width: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 50%;
  background: var(--surface-surface_1, #F7F7F6);
  cursor: pointer;
}

.actionButton:hover {
  background: var(--surface-surface_2, #EDEDEB);
}

.paginationContainer {
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;
  height: 48px;
}

.offerButton {
  display: flex;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border: 0px;
  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-surface_1, #F7F7F6);

  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 16px; /* 133.333% */
}

.noApplicantsText {
  text-align: center;
}
