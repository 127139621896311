@import '../../styles/marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
  min-height: calc(100vh - var(--topbarHeightDesktop));

  @media (--viewportMedium) {
    padding: 18px 10px 0 10px;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 20px 0 20px;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: var(--SearchPage_containerHeight);
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

/*Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-radius: 20px;
  border: 2px solid black;
  height: 50px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.topbarSearchWithLeftPadding {
  /* margin-top: 10px;
  border-radius: 20px;
  border: 2px solid black; */
  height: 50px;
  padding-left: 24px;
  /* height: var(--topbarHeightDesktop); */

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

.buttonsContainer {
  margin-top: var(--fontSize);
  display: flex;
  justify-content: space-around;
}
