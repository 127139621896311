@import '../../styles/marketplace.css';

.wrapperMain {
  margin: 0 auto;
  padding: 32px;
  background-color: #f8f7f5;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportMedium) {
    margin: 0px;
  }
}

.baseContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.left {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportMedium) {
    width: 30%;
    max-width: 300px;
  }
}

.offerImageContainer {
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
}

.offerImageContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardContainer {
  padding: 20px;
  border-radius: 20px;
  background-color: #FFF;
}

.applyButton {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--surface-surface_high_em, #292929);
  border: 0px;

  color: var(--text-white, #FFF);

  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */

  cursor: pointer;
}

.applyButton:hover {
  background-color: #000;
}

.appliedButton {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-surface_1, #F7F7F6);

  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.right {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  align-items: start;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 0px;
  }
}

.campaignNameText {
  color: var(--text-high_em, #0C0C0C);

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  letter-spacing: -0.24px;
}

.offerText {
  color: var(--text-high_em, #0C0C0C);

  font-family: Unbounded;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
}

.contentText {
  white-space: pre-wrap;
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.eventDateText {
  color: var(--text-med_em, #434343);
  text-align: right;

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.subtitleText {
  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.flex24 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.flex16 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tagItem {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: var(--radius-border_radius_8, 8px);
  background: var(--surface-surface_4, #EAEAE8);

  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.clientProfileContainer {
  width: 100%;
  display: flex;
  gap: 12px;
}

.avatarImageContainer {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.avatarImageContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menuSubtitleText {
  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.campaignInfoItemContainter {
  display: flex;
  align-items: center;
  gap: 12px;
}

.iconButton {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-spacing-xs, 6px);
  border-radius: var(--radius-border_radius_100, 100px);
  background: var(--surface-surface_2, #F2F2F1);
}

.socialMediaGrid {
  width: 100%;
  display: flex;
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 8px;
}

.socialMediaItem {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-surface_1, #F7F7F6);
}

.socialMediaItem:hover {
  background-color: #F2F2F1;
}

.campaignStatusChip {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: var(--radius-border_radius_6, 6px);
  background: var(--surface-success_accent_1, #DAFBE7);

  color: var(--color-success-800, #0E813C);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
