@import '../../../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesFieldArray {
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin: 0 0 24px 0;
}

.thumbnail {
  width: 100%;
  background-color: var(--colorGrey50);
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    background-color: var(--colorGrey50);
  }

  border-style: dashed;
  border-color: var(--colorGrey100);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--colorGrey300);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--colorGrey700);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey300);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--colorGrey300);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--colorFail);
}

.arrayError {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorFail);
  width: 100%;
  clear: both;

  margin-top: 6px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 0px;
  margin-top: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
    margin-bottom: 24px;
  }
}

/* //////////////////////////// */
:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPhotosForm_imageWidthDesktop: calc(50% - 12px);
}

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
  position: relative;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 0px;
  margin-top: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
    margin-bottom: 24px;
  }
}

.videosContainer {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.video {
  position: relative;
}

.addVideo {
  width: 366px;
}

.removeButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti);
  }

  &:hover svg {
    fill: var(--matterColorLight);
    stroke: var(--matterColorLight);
  }
}

.noPreview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.progress {
  position: absolute;
  top: calc(50% + 30px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.preview {
  background-color: rgba(0, 0, 0, 0.2);
}

.removeVideo {
  left: 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  gap: 8px;

  @media (--viewportLarge) {
    gap: 16px;
    flex-direction: row;
  }
}

.buttonContainer a {
  @media (--viewportLarge) {
    margin-top: 77px;
  }
}
