.text {
  white-space: pre-wrap;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.btn {
  width: 100%;
}
