.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}


.subtitle {
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.grid_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.emailContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
