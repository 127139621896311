@import '../../styles/marketplace.css';
:root {
  --EditListingBackgroundAndExperienceForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}
.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.error {
  color: var(--failColor);
}

.sports,
.achievements,
.about,
.specializations,
.categories,
.activities,
.ageGroups,
.virtualTrainingBlock,
.rules,
.distanceBlock,
.area,
.aboutInfo {
  @apply --EditListingBackgroundAndExperienceForm_formMargins;

  display: block;
  /* margin-left: 20px; */

  @media (--viewportMedium) {
    display: inline-block;
  }
}

/* // */

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.locationAddress,
.building {
  margin-bottom: 24px;
}
