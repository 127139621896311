@import '../../styles/marketplace.css';

.root {
  /* @apply --marketplaceModalFormRootStyles; */
  color: var(--matterColorDark);
  flex: 2;
  /* flex-grow: 1; */
  flex-shrink: 2;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  margin: 40px;
}

.header {
  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin: 0 0 16px 0;
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #656565;
}

.inputs {
  margin-top: 40px;
}

.input {
  margin-top: 10px;
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 56px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
  color: var(--marketplaceColor);
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}

.submitButton {
  width: 100%;
}
