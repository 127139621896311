.wrapperMain {
  height: 100vh;
  width: 100%;
  background-color: #f8f7f5;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.container {
  flex: 1 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: auto;
}

.left {
  overflow: auto;
  background-color: #FFF;
  padding: 32px;
}

.headerText {
  font-family: 'Unbounded';
  color: black;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
}

.subheaderText {
  font-size: 16px;
  font-weight: 400;
}

.formContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.label {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 8px;
}

.documentInputContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 12px;
}

.selectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;

  font-size: 16px;
  font-weight: 400;
  font-family: 'Afacad';
  cursor: pointer;
}

.selectSelected {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(124, 58, 237);
}

.selectNotRequired {
  grid-column: 1 / 4;
}

.right {

}

.footer {
  border-top: 1px solid #e0e0e0;
  height: 84px;
  background-color: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerContainer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
}

.footerBtnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.inputText {
  padding: 8px 12px;

  border-radius: var(--radius-border_radius_12, 12px);
  border-width: 0px;
  background-color: var(--surface-surface_2, #F2F2F1);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.inputSelect {
  padding: 8px 12px;

  border-radius: var(--radius-border_radius_12, 12px);
  background: var(--surface-surface_2, #F2F2F1);
  border: 1px solid var(--outline-low_em, #EEEEED);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  } */

.contractDurationContainer {
  display: grid;
  grid-template-columns: 1fr 150px;
  gap: 12px;
}

.flexGrow {
  flex-grow: 1;
}

.budgetContainer {
  display: grid;
  grid-template-columns: 1fr 100px 2fr min-content 2fr;
  gap: 12px;
}

.errorMessage {
  color: #f35454;
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.centerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}
