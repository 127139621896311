.formContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputText {
  height: 40px;
  padding: 0px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  border: 1px solid var(--outline-low_em, #EEEEED);
  background: var(--surface-surface_0, #FFF);

  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.inputSelect {
  display: flex;
  height: 40px;
  padding: 0px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  border: 1px solid var(--outline-low_em, #EEEEED);

  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.errorMessage {
  margin-bottom: 8px;
  color: var(--typo-primary, #f35454);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.grid_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.buttonTextWithLoading {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
}
