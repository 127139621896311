@import '../../styles/marketplace.css';

.breadcrumb {
  display: inline-flex;
  width: 100%;
  gap: 8px;
  font-size: 20px !important;

  flex-wrap: wrap;

  @media(--viewportLarge) {
    gap: 16px;
  }
}

.flex {
  display: flex;
  gap: 8px;

  @media(--viewportLarge) {
    gap: 16px;
  }
}

.breadcrumbItem {
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
}

.active {
  font-weight: 700;
  color: #131313;
}
