@import '../../styles/marketplace.css';

.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.sidebar {
  padding: 0px;
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  overflow: hidden;

  background: var(--surface-surface_0, #292929);

  @media (--viewportMedium) {
    position: relative;
    margin: 0;
    /* padding-top: 10px; */
  }
}

.containerAuthentication {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}
