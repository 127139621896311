@import '../../styles/marketplace.css';

.backButton {
  background-color: #0f69e0;
  max-width: 103px;
  width: 100%;
  min-height: 45px !important;
  position: relative;
  margin-right: 10px;
  /* left: 30px; */
  /* top: 50px; */
  &:hover {
    background-color: rgb(0, 89, 255);
  }

  @media (--viewportSmallMax) {
    position: initial;
  }
}
