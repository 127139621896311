@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.wrapperMain {
  padding: 0px;
  @media (--viewportMedium) {
    max-width: 1260px;
    margin: 0 auto;
    padding: 32px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-grow: 1;
}

.error {
  color: var(--failColor);
}

.tabContent {
  display: none;
}

.tabContentVisible {
  display: block;
}

.footer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* OrderBreakdown subcomponent */
.breakdown {
  padding: 1px 0 5px 0;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
  }
  @media (--viewportLarge) {
    padding: 6px 0 2px 0;
  }
}

/* OrderPanel subcomponent */
.orderPanel {
  margin: 16px 48px 48px 48px;
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 1px;
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.breakdownRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.key {
  color: #656565;
}

.value {
  color: #1c1c1c;
}

.horizontalRule {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ddd;
  margin-top: 16px;
  margin-bottom: 16px;
}

.subTitle {
  font-weight: 700;
  color: #1c1c1c;
  margin-bottom: 16px;
}

.rejectDisputeButton {
  margin-top: 40px;
}

.editLink {
  color: #dd58d6;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 8px;
  cursor: pointer;
}

.flexContainer {
  display: flex;
}

.svgContainer {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.datePicker {
  /* width: 100px; */
  @media (--viewportMedium) {
    width: 200px;
  }
}

.fieldSelect {
  /* width: 100px; */
  @media (--viewportMedium) {
    width: 200px;
  }
}

.deadlineSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 8px;
}

.datepickerDropdown {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;

  @media (--viewportMedium) {
    width: 480px;
  }
}

.reviewModal {
  background: #ffffff;
  padding: 32px;
  border-radius: 24px;
}
