.grouping {
  border-radius: 10px;
  padding: 10px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid lightgray;
}

.btnSubmitDraft {
  width: 100%;
  border: 1px solid #121212;
  border-radius: 50px;
  padding: 8px;
  cursor: pointer;
}

.btnSubmitPublish {
  margin-top: 10px;

  width: 100%;
  border: 1px solid #DD58D6;
  background-color: #DD58D6;
  color: white;
  border-radius: 50px;
  padding: 8px;
  cursor: pointer;
}