@import '../../../styles/customMediaQueries.css';

.panel {
  flex-grow: 1;
  padding: 11px 24px 0 24px;

  @media (--viewportLarge) {
    padding: 48px 36px 82px 59px;
    border-left: 1px solid var(--colorGrey100);
    background-color: var(--colorWhite);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px calc((100% - 1056px) / 2) 82px 59px;
  }
}
