@import '../../styles/marketplace.css';

.wrapperMain {
  margin: 0 auto;
  padding: 0px;
  background-color: #f7f7f6;
}

.content {
  padding: 40px 32px;
  padding-bottom: 100px;
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.title {
  font-size: 24px;
  font-weight: 700;
  font-family: Unbounded;
  color: var(--color-gray-900, #1c1c1c);
}

.search {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #ddd;
  width: 100%;
  margin-top: 24px;
  background-color: #fff;
}

.search form {
  flex: 1;
}

.search input {
  padding: 0;
  border-bottom: none;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 16px 16px;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (--viewportLarge) {
    margin-bottom: 16px;
  }
}

.cardContainer {
  width: 100%;
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.actionsContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 24px 90px 24px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  border-top: 1px solid #ececed;
}

.actions {
  position: relative;
  bottom: 0;
  width: 1160px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  border-top: 1px solid #ececed;
  padding: 0 32px;
}

.selectedText {
  color: var(--text-high_em, #0c0c0c);
  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.actionButton {
  border-radius: 64px;
  padding: 12px 16px;
}

.center {
  display: flex;
  justify-content: center;
  height: 100%;
}
