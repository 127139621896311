.base {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: fit-content;

  border-radius: var(--radius-border_radius_8, 8px);
  background: var(--surface-surface_4, #EAEAE8);

  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.green {
  background: var(--color-success-100, #C8F9DC);
  color: var(--color-success-800, #0E813C);
}

.gray {
  background: var(--surface-surface_4, #EAEAE8);
  color: var(--text-med_em, #434343);
}

.blue {
  background: var(--surface-info_accent_1, #EEF2FB);
  color: var(--color-info-600, #2750B0);
}
