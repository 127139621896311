.wrapperMain {
  margin: 0 auto;
  padding: 32px 24px;
  background-color: #f8f7f5;

  @media (--viewportMedium) {
    padding: 32px;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.headerTitle {
  font-family: 'Unbounded';
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  color: #1c1c1c;
}

.newBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.contentContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.table {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  border-collapse: collapse;
  overflow: hidden;
}

.table th {
  padding: 16px !important;
  color: #8c8a94;
  font-weight: 400;
  text-align: left;
}

.table th:nth-child(5) {
  display: flex;
}

.table td {
  padding: 16px;
  background-color: #ffffff;
  text-align: left;
  border-top: solid #f3f3f4 1px;
}

.newJobTitle {
  margin-bottom: 24px;
}

.newJobButtonContainer {
  margin-top: 12px;
}

.newJobButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.jobButtonContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.deleteJobButtonContainer {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
