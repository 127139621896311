@import '../../styles/marketplace.css';

.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.wrapper {
  max-width: 1260px;
  margin: 0 auto;
  padding: 32px;
  width: 100%;
}

.modalStyle {
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
  min-width: 600px;

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 20%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    @apply --marketplaceModalRootStyles;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    position: relative;

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    @apply --marketplaceModalBaseStyles;
    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 576px;
      min-height: auto;
      height: auto;
    }
  }
}

.header {
  color: #100c20;
  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.24px;
  margin: 0;
}

.subtitle {
  color: var(--text-med_em, #494656);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

.serviceCardsContainer {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 40px;
  gap: 40px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.serviceCard {
  padding: 24px;
  text-align: left;
  border: 1px solid #f8f7f5;
  border-radius: 20px;
  box-shadow: 0px 1px 16px 0px rgba(50, 50, 71, 0.1), 0px 0px 2px 0px rgba(12, 26, 75, 0.18);

  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 650px;
}

.cardHeader {
  color: #100c20;
  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.16px;
  margin: 32px 0 0 0;
}

.cardSubHeader {
  color: #8c8a94;
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
}

.cardText {
  color: #494656;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.cardTextContainer {
  flex: 1 0 0;
  overflow: auto;
  color: #494656;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.detailContainer {
  margin-top: 16px;
}

.list {
  list-style-type: disc; /* Use 'circle' for hollow bullets */
  padding-left: 20px;    /* Ensure there's space for bullets */
  display: list-item;    /* Ensure it's using list-item display */
  visibility: visible;   /* Ensure it's visible */
}

.detailText {
  color: #494656;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.firstCardText {
  max-width: 240px;
}

.requestButton {
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
  font-family: Afacad;
  font-weight: 700;
  line-height: 20px;
}

.modalTitle {
  color: #100c20;
  font-family: Afacad;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.24px;
  margin-top: 24px;
}

.modalSubTitle {
  color: #494656;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

.spinner {
  stroke: #ffffff;
  height: 20px;
}

.error {
  color: red;
  text-align: center;
  margin-top: 40px;
}
