@import '../../styles/marketplace.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColorDark);
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;
    color: var(--marketplaceColor);

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }
}
