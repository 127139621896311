@import '../../styles/marketplace.css';

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  width: 100%;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}

.image {
  width: 100%;
  height: 180px;
  position: relative;
}

.product_image {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description {
  padding: 16px;
  align-self: stretch;
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.title {
  flex: 1 0 0;
  color: #1c1c1c;
  font-family: Unbounded;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
}

.brand_text {
  padding-top: 16px;
  color: #494656;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.date_text {
  margin-top: 4px;
  color: #dd58d6;
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.statusContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.status_badge {
  padding: 4px 12px;
  text-align: center;
  border-radius: 6px;
  align-self: flex-start;
  width: fit-content;
}

.status_active {
  color: #0ed065;
  background-color: #e3ffee;
}

.status_completed {
  color: #666471;
  background-color: #f3f3f4;
}
