.serviceCard {
  padding: 24px;
  width: 300px;
  text-align: left;
  border: 1px solid #f8f7f5;
  border-radius: 20px;
  box-shadow: 0px 1px 16px 0px rgba(50, 50, 71, 0.1), 0px 0px 2px 0px rgba(12, 26, 75, 0.18);
}

.cardHeader {
  color: #100c20;
  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.16px;
  margin: 32px 0 0 0;
}

.cardSubHeader {
  color: #8c8a94;
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
}

.cardText {
  color: #494656;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.requestButton {
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
  font-family: Afacad;
  font-weight: 700;
  line-height: 20px;
}
