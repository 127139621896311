@import '../../styles/marketplace.css';

.emptyView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  padding: 24px;
}

.emptyImage {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.emptyViewHeader {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #100c20;
}

.emptyViewBody {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #100c20;
}
