.inputContainer {
  width: 100%;
}

.inputWrapper {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 8px 12px;
  width: 100%;
}

.inputIcon {
  color: #888888;
  margin-right: 8px;
  margin-top: -3px;
}

.input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  color: #333333;
  padding: 0px;
}

.input::placeholder {
  color: #cccccc;
}
