.card {
  border-radius: 16px;
  background-color: #fff;
  /* max-width: 350px; */
}

.header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.type {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.checkbox {
  width: 24px;
  height: 24px;
}

.image {
  object-fit: contain;
  height: 200px;
  width: 100%;
}

.footer {
  padding: 16px;
}

.tagContainer {
  display: flex;
  gap: 8px;
}

.tag {
  padding: 2px 6px;
  width: fit-content;
  border-radius: 6px;
  color: #692dbd;
  background: var(--color-primary-100, #e6d7fb);
}

.tagMore {
  padding: 2px 6px;
  width: fit-content;
  border-radius: 6px;
  border: 1px solid var(--outline-low_em, #eeeeed);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.priceAndUrl {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fromText {
  color: var(--text-low_em, #828282);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.priceText {
  margin-top: -5px;
  color: var(--text-high_em, #0c0c0c);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.url {
  cursor: pointer;
}
