.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.text {
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.subText {
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  font-style: italic;
}

.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
