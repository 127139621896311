@import '../../styles/marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
}

.bookingHandleSection {
  padding: 28px 40px;
  border-radius: 8px;
  background-color: #fff;
}

.bookingDates {
  flex-shrink: 0;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  max-width: 424px;
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: #1c1c1c;

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.infoPrint {
  @apply --marketplaceTinyFontStyles;
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;
}

.errorPrint {
  color: red;
  text-align: center;
  flex-shrink: 0;
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding-top: 24px;
  max-width: 424px;

  @media (--viewportMedium) {
  }
}
.requestSession {
  background-color: #0f69e0;
  color: #ffffff;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  /* height: 40px; */
  line-height: 24px;
  border: none;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #0f69e0;
  }
  /* &:focus {
    background-color: rgb(0, 89, 255);
  } */
  /* &:disabled {
    background-color: rgb(0, 89, 255);
  } */
}
.location {
  width: 100%;
  margin-top: 24px;

  @media (--viewportMedium) {
    width: 100% !important;
  }

  @media (--viewportLarge) {
    width: 100% !important;
  }

  @media (--viewportSmall) {
    width: calc(100% - 45px);
    margin: 0 auto;
  }
  @media (--viewportSmallMax) {
    width: calc(100% - 45px);
    margin: 0 auto;
  }
}
.location_address {
  @media (--viewportSmall) {
    margin-top: 12px !important;

    width: calc(100% - 45px);
    margin: 0 auto;
  }

  @media (--viewportMedium) {
    width: 100% !important;
  }

  @media (--viewportLarge) {
    width: 100% !important;
  }

  @media (--viewportSmallMax) {
    margin-top: 24px !important;

    width: calc(100% - 45px);
    margin: 0 auto;
  }
}

.locationContainer,
.onlineLocationContainer {
  display: flex;
  margin-top: 24px;
  gap: 12px;

  @media (--viewportMedium) {
    gap: 16px;
    margin-top: 16px;
  }
}

.onlineLocationContainer {
  margin-top: 12px;
}

.locationButton {
  border-radius: 36px;
  border: 1px solid #ddd;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.sectionTitle {
  font-weight: 600;
  line-height: 30px;
  font-size: 18px;
  color: #1c1c1c;
}

.address {
  margin-top: 24px;
  color: #656565;
  line-height: 24px;
  font-size: 16px;
}

.map {
  margin-top: 12px;
  border-radius: 12px;
}

.active {
  background-color: #0f69e0;
  color: #fff;
}

.infoLabelContainer {
  margin-top: 16px;
}

.infoLabel {
  color: #656565;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
}

.bookingInfoSection {
  margin-top: 24px;
  padding: 28px 40px;
  background-color: #fff;
  border-radius: 8px;
}

.imageSmall {
  width: 52px;
  height: 52px;
  border-radius: 8px;
  margin-right: 12px;
}

.listingInfo {
  display: flex;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 44px;
  }
}

.bookingPeriod {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.priceDetailsContainer {
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  font-size: 20px;
  line-height: 30px;
  color: #1c1c1c;
  margin: 0;
  font-weight: 600;
}

.location {
  margin: 0;
  color: #656565;
  line-height: 24px;
  font-size: 16px;
}

.priceHeader {
  font-size: 18px;
  font-weight: 600;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.bookingDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 16px;
  color: #1c1c1c;
}

.bookingInfo {
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
    gap: 40px;
  }
}

.buttonLabel {
  margin: 0;
  color: #656565;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  text-align: center;
}

.locationRow {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.dayInfo,
.locationTitle {
  color: #959595;
}

.contactError {
  color: red;
  font-size: 16px;
  text-align: center;
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 101;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px 40px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    /* display: none; */
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  color: #1c1c1c;
  font-size: 24px;
  line-height: 32px;
}

.perUnit {
  color: #7a7a7a;
  font-size: 18px;
  line-height: 30px;
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;
  height: 40px;
  width: 104px;
  font-size: 16px;
  padding: 8px 16px;
}

.button {
  border-radius: 8px;
  border: none;
  width: 100%;
  background-color: #f3f3f3;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-top: 12px;
}
