
    font-family: 'Afacad', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1px;
    

    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 24px;
    }
  