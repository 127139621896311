
    font-weight: var(--fontWeightBold);
    text-align: center;
    font-family: 'Unbounded';
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.96px;

    @media (--viewportMedium) {
      text-align: center;
      font-family: 'Unbounded';
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -1.44px;
    }
  