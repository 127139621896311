
    font-family: 'Afacad', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 13px;
      line-height: 24px;
    }
  