.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
    gap: 16px;
  }
}

.form {
  @media (max-width: 768px) {
    flex: 1;
  }
}

.button {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  color: #100c20;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  padding: 8px 16px;
  border-radius: 64px;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    max-width: 400px;
    width: 100%;
    justify-content: center;
  }
}

.button .tiktokLogo {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.button .instagramLogo {
  margin-right: 8px;
  height: 20px;
  width: 20px;
}
