@import '../../styles/marketplace.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  /* background-color: var(--matterColorDark); */
  background-color: #292929;

  @media (--viewportMedium) {
    padding: 0 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 0 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 30px;
  }

  @media (--viewportLarge) {
    padding-top: 25px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Anna Changes */
  /* @media (--viewportMedium) {
    padding: 0px 40px;
    margin: 0 auto;
  } */

  @media (--viewportMedium) {
    margin: 0 auto;
  }
  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }
  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.someLinks {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  padding: 0 3% 0 3%;
  text-align: center;
  /* max-width: 40%; */
  min-width: 20%;
  width: 100%;
  @media (--viewportMedium) {
    width: 0%;
  }
}

/* Mobile only social media links */
.someLinksMobile {
  order: 2;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: block;
  width: 100%;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  display: none;
  margin-right: 24px;

  @media (max-width: 767px) {
    margin: auto;
    display: flex;
    order: 4;
  }

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    order: 1;
  }
  @media (--viewportLarge) {
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    margin-right: 0;
  }
}

.logo {
  object-fit: contain;
  object-position: center center;
  width: 100%;
  height: 20px;
}

.organizationDescription {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLight);
  margin-top: 15px;

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  order: 2;
  flex-basis: auto;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (max-width: 767px) {
    margin: auto;
    order: 1;
  }
}

.listHeader {
  font-family: Afacad;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
  font-style: normal;
  font-size: 18px;
  color: var(--matterColorLight);
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.list {
  margin-top: 14px;
  text-align: center;
  margin-bottom: 55px;
}

.listMobile {
  margin: 0;
  text-align: center;
}

.listItemMobile {
  margin-right: 0;
}

.listItemBreak {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;
  margin-right: 10px;
  display: flex;

  font-family: Afacad;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  @media (--viewportMedium) {
    margin-bottom: 6px;
    font-size: 16px;
  }
}

.listItem > .contactUsLink {
  font-size: 16px;
}

.tosAndPrivacy > .listItem {
  display: inline-block;
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.cursorPointer {
  cursor: pointer;
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.contactUsLink {
  color: var(--matterColorLight);
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 12px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  font-size: 16px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.startupLogo {
  width: 210px;
}

/* Logos Ordering */
.mobileStartupLogoLink {
  order: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    order: unset;
  }
}

.logoLink {
  order: 3;
  display: block;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    order: unset;
  }
}

.someLinksContainer {
  order: 2;
  display: flex;
  width: 100%;
  justify-content: center;

  @media (--viewportMedium) {
    order: unset;
  }
}

.organizationInfo {
  order: 4;
  flex: 1 0 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: end;
    order: unset;
  }
}

.video {
  width: 100%;
  min-height: 400px;
  border: 0;
  border-radius: 12px;
}

.modeButton {
  display: inline-block;
  border-radius: 12px;
  padding: 1px 8px;
  font-family: Afacad;
  border: 1px solid #292929;
}

.modeButtonActive {
  background-color: #292929;
  color: var(--matterColorLight);
}

.modeButtonContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}
