@import '../../styles/marketplace.css';

.listingCard {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-m, 16px);
  background: #fff;
  position: relative;

  /* shadow-m */
  box-shadow: 0px 1px 16px 0px rgba(50, 50, 71, 0.1), 0px 0px 2px 0px rgba(12, 26, 75, 0.18);

  &:hover {
    text-decoration: none;
  }
}

.listingTitle {
  color: var(--text-high_em, #100c20);
  /* font_primary/heading 1/bold */
  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
}

.listingImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.listingLinkTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.profile {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  z-index: 1;

  &:hover {
    text-decoration: none;
  }
}

.profileContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.profileTitle {
  color: #1c1c1c;
  /* CSG 16 Semibold */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  margin: 0;
}

.profileRole {
  color: #656565;

  /* ProLed 14 Regular */

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.profile {
  padding: 16px;
}

.listingImageContainer {
  position: relative;
  width: 100%;
  height: 200px;
}

.content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  gap: 24px;
  /* height: 100%; */
}

.categories {
  display: flex;
  gap: 8px;
}

.category,
.niche,
.labelAmount {
  padding: 4px 16px;
  border-radius: var(--radius-s, 8px);
  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
  color: var(--badge-brand1-text, #dd58d6);
  background: var(--badge-brand1-fill, rgba(244, 122, 244, 0.16));
}
.niche {
  color: var(--badge-brand2-text, #ff972c);
  background: var(--badge-brand2-fill, #fff1d2);
}
.pricingContainer {
  display: flex;
  justify-content: space-between;
}

.labelAmount {
  border: 1px solid #ddd;
  background-color: unset;
  color: #656565;
  font-weight: 600;
}

.priceLabel {
  color: var(--typo-secondary, #656565);
  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.price {
  color: var(--typo-primary, #1c1c1c);

  /* CSG 16 Semibold */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  z-index: 1;

  & > div {
    cursor: pointer;
  }
}

.video {
  position: relative;
  z-index: 2;
}
