
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    border-radius: 50%;

    
    display: flex;
    align-items: center;
    justify-content: center;

    
    background-image: linear-gradient(
      -180deg,
      var(--matterColorDark) 25%,
      var(--marketplaceColorDark) 100%
    );
    color: var(--matterColorLight);

    &:hover {
      text-decoration: none;
    }
  