.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.modalContent {
  margin-top: 24px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: #131313;
}

.description {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #494656;
}

.children {
  margin-top: 24px;
}

.modalActions {
  margin-top: 32px;
  display: flex;
  gap: 16px;
  justify-content: right;
}

.okButton {
  padding: 12px 16px;
  width: auto;
  border-radius: 64px;
}

.cancelButton {
  padding: 12px 16px;
  width: auto;
  border-radius: 64px;
}
