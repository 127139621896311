@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);
  display: flex;
  align-items: center;
  gap: 8px;
  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.inputContainer {
  display: block;
  width: 100%;
  margin-right: 8px;
}

.textarea {
  padding: 8px;
  padding-left: 20px;
  line-height: 24px;
  font-size: 16px;
  color: #494656;
  background-color: #f3f3f4;
  border-radius: 48px;
  border: none;
}
.textarea::placeholder {
  position: relative;
  top: 5px;
  line-height: 20px;
}

.spinner {
  stroke: var(--colorWhite);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--colorSuccess);
}

.strokeMatter {
  stroke: var(--colorGrey700);
}

.submitContainer {
  display: flex;
  flex-direction: column;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--colorFail);
  margin: 0;
}

.submitButton {
  padding: 8px 12px;
  border-radius: 48px;
  width: 40px;
  height: 40px;
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.messageInputContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.modalBackground {
  position: absolute;
  bottom: 100px;
  left: -20px;

  background-color: rgba(0, 0, 0, 0.5);
  padding: 40px;
  border-radius: 8px;
  z-index: 10;
}

.closeContainer {
  width: fit-content;
  color: #ffffff;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.sendFileButton {
  margin-top: 24px;
  width: 100%;
}
