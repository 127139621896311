@import '../../styles/marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileMenu_topMargin: 96px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  color: var(--matterColorDark);
  height: 92svh;
}

.contentBasic {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
  margin-top: 50px;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
}

.contentWrapper {
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 300px;
  padding: 24px;
  background-color: var(--marketplaceColor);
  box-shadow: var(--boxShadowTop);

  @media (--viewportMedium) {
    width: 100%;
  }
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  /* margin: 50px 0 0 0; */
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.menuLink {
}

.logoutButton {
  @apply --marketplaceButtonStyles;
  padding: 0;
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
}

.inbox {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  /* position: relative; */

  margin-top: 0;
  margin-bottom: 11px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.avatarContainer {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.role {
  width: 110px;
  margin: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 28px;
}

.navigationContainer {
  padding-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.upButton,
.downButton {
  cursor: pointer;
  display: flex;
}

.buttonIcon {
  height: 14px;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.switcher {
  width: 232px;
  height: 40px;
  border-radius: 8px;
  background-color: #f6f6f6;
  font-size: 14px;
  padding: 4px;
  transition: 0.3s;
  display: flex;
  gap: 4px;
  margin-top: 30px;
}

.switcherButton {
  width: 110px;
  height: 32px;
  border-radius: 6px;
  background-color: #f6f6f6;
  border: none;
  cursor: pointer;
  color: #656565;
}

.active {
  background-color: #fff;
  color: #1c1c1c;
}

.navigationLink {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  white-space: nowrap;
  transition: 0.2s;
  width: 100%;

  /* Font */
  /* @apply --marketplaceTabNavFontStyles; */

  color: #656565;
  padding: 10px 12px;

  /* push tabs against bottom of tab bar */
  margin-top: 0;
  margin-bottom: 8px;
  border-radius: 8px;
  transition: var(--transitionStyleButton);
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: #1c1c1c;
    background-color: #eff6fb;
  }

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }

  cursor: pointer;
}

.tabContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.tabTitle {
  margin-left: 16px;
}

.linkWrapper {
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.becomeProBanner {
  /* min-width: 100%; */
  /* display: flex;
  flex: 1; */
  /* height: 100px; */
  background-color: rgba(255, 205, 156, 0.2);
  border: 1px solid rgba(215, 135, 85, 0.2);
  border-radius: 8px;
  padding: 16px;
  /* margin-bottom: 30px; */
  margin-top: 20px;
  transition: 0.3s;
}

.becomeProText {
  color: #1c1c1c;
  font-size: 16px;
  line-height: 24px;
}

.horizontalLine {
  width: 100%;
  height: 1px;
  border: 1px solid #e9e9e9;
  margin-top: 8px;
  margin-bottom: 16px;
}

.bottomSettingsContainerWrapper {
  width: 100%;
}

.bottomSettingsContainer {
  margin-top: 40px;
  width: 100%;
}

.currentPageActive {
  background-color: #dfedf7;
  color: #313131;
}

.profileContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.profile {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 16px;
}

.profileName {
  color: var(--typo-primary, #1c1c1c);

  /* CSG 16 Semibold */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  margin: 0;
  text-overflow: ellipsis;
}

.profileRole {
  color: var(--typo-secondary, #656565);
  text-align: center;

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.avatar {
  width: 40px;
}

.hiddenNone {
  display: none;
}

.menuLabel {
  border-radius: var(--radius-border_radius_12, 12px);
  background: var(--surface-surface_2, #f3f3f4);
  padding: 8px 12px;

  &:hover,
  &:active {
    background: var(--surface-surface_2, #f3f3f4);
  }
}

.menuContent {
  z-index: 10;
  display: block;
  padding: 8px 0;
  top: -120px;
  width: 150px;
  border-radius: var(--radius-border_radius_12, 12px);
  border: 1px solid var(--outline-base_em, #f3f3f4);
  background: #fff;

  /* elevation/e2 */
  box-shadow: 0px 3px 4px -2px var(--effect-shadow_e2, rgba(0, 0, 0, 0.07));
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background: #f3f3f4;
  }
}

.menuItem span {
  color: var(--typo-secondary, #656565);

  /* CSG 20 Regular */
  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.tabWithNotifications {
  position: relative;
}

.notificationDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 10px;
  left: 30px;
}

.actionButtonContainer {
  
}
