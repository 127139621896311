@import '../../../styles/marketplace.css';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 24px;
  /* border-radius: 64px 64px 0px 0px; */
  background: #ffffff;
  max-width: 100vw;
}

.cta {
  position: relative;
  text-align: center;
}

.image {
  max-width: 100%;
  content: url('../../../assets/become-pro/cta-background-mobile.svg');

  @media (--viewportMedium) {
    content: url('../../../assets/become-pro/cta-background-desktop.svg');
  }
}

.content {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (--viewportMedium) {
  }
}

.title {
  color: #ffffff;
  font-family: Unbounded;
  font-size: 24px;
  font-weight: 700;
  line-height: 46px;

  @media (--viewportMedium) {
    font-size: 32px;
    white-space: nowrap;
  }
}

.btn_join {
  margin: 32px auto 0px auto;

  display: flex;
  justify-content: center;
  gap: 8px;

  height: 48px;
  padding: 12px 16px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--text-white, #fff);
  border: 0;

  color: var(--color-dark-700, #292929);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    background-color: #d8d8d2;
  }

  @media (--viewportLarge) {
    flex-grow: 0;
    /* width: fit-content; */
    white-space: nowrap;
  }
}
