@import '../../../../styles/marketplace.css';

.progressBar {
  width: 120px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 12px;

  @media (--viewportMedium) {
    width: 200px;
  }
}

.progress {
  height: 10px;
  background-color: #8338ec;
  width: 0%;
  transition: width 0.5s ease-in-out;
}
