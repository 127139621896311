@import '../../styles/marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.wrapperMain {
  max-width: 1260px;
  margin: 0 auto;
  padding: 32px;
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.listItem {
  margin-bottom: 15px;
}

.searchButton {
  @apply --marketplaceButtonStyles;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

/* .listItem {
  margin: 30px 0;
} */

.editLinkDesktop {
  /* @apply --marketplaceLinkStyles; */
  margin: 10px 0;
  cursor: pointer;
  color: black;
}

.editLinkDesktop:hover {
  text-decoration: none;
}

.active {
  color: var(--marketplaceColorLight);
  text-decoration: underline;
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
}
