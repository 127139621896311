@import '../../../../../styles/marketplace.css';

.wrapper {
  display: flex;

  flex-direction: column;
  max-width: 1160px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.content {
  margin-top: 32px;
  max-width: auto;
  @media (--viewportLarge) {
    margin-top: 48px;
    max-width: 400px;
  }
}

.imageContainer {
  order: -1;
  text-align: center;

  @media (--viewportLarge) {
    order: 0;
  }
}

.image {
  max-width: 100%;
  border-radius: 15px;

  @media (--viewportLarge) {
    max-width: 700px;
  }
}

.title {
  color: var(--text-high_em, #fff);

  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-top: 16px;

  @media (--viewportLarge) {
    margin-top: 32px;
    font-size: 24px;
  }
}

.description {
  margin-top: 8px;

  color: #cccccc;
  font-size: 16px;
  font-weight: 400;

  @media (--viewportLarge) {
    margin-top: 16px;

    font-size: 18px;
  }
}

.btn_join {
  margin-top: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 48px;
  padding: 12px 16px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--text-white, #fff);
  border: 0;

  color: var(--color-dark-700, #292929);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;

  @media (--viewportLarge) {
    flex-grow: 0;
    width: fit-content;
    white-space: nowrap;
  }

  cursor: pointer;
  &:hover {
    background-color: #d8d8d2;
  }
}

.btn_join {
  margin-top: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 48px;
  padding: 12px 16px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--text-white, #fff);
  border: 0;

  color: var(--color-dark-700, #292929);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;

  @media (--viewportLarge) {
    flex-grow: 0;
    width: fit-content;
    white-space: nowrap;
  }

  cursor: pointer;
  &:hover {
    background-color: #d8d8d2;
  }
}

.btnContainer {
  margin-top: 32px;

  display: flex;
  gap: 16px;
  align-items: center;
}

.btnWhiteOutline {
  height: 48px;
  padding: 12px 16px;
  flex: 1;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 1px solid var(--surface-white, #fff);

  color: var(--surface-white, #fff);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportLarge) {
    flex-grow: 0;
    width: 300px;
    white-space: nowrap;
  }
}

.btn_join {
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 48px;
  padding: 0px 16px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--text-white, #fff);
  border: 0;

  color: var(--color-dark-700, #292929);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;

  @media (--viewportLarge) {
    flex-grow: 0;
    width: fit-content;
    white-space: nowrap;
  }

  cursor: pointer;
  &:hover {
    background-color: #d8d8d2;
  }
}
