.container {
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  background: var(--surface-surface_4, #EAEAE8);
}

.container:hover {
  cursor: pointer;
  background: var(--surface-surface_4, #d1d1d1);
}

.title {
  display: flex;
  gap: 8px;
  align-items: center;

  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */

  margin-bottom: 4px;
}

.description {
  color: var(--text-med_em, #434343);
  text-align: center;

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
