.wrapperMain {
  margin: 0 auto;
  padding: 32px 24px;
  background-color: #f8f7f5;

  @media (--viewportMedium) {
    padding: 32px;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.headerTitle {
  font-family: 'Unbounded';
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  color: #1c1c1c;
}

.newBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.contentContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.jobContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.noJobContainer {
  flex: 1 0 0;
  height: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noJobLabel {
  font-family: 'Afacad';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #0c0c0c;
}

.noJobText{
  color: #434343;
  max-width: 400px;
  font-weight: 400;
  text-align: center;
  white-space: pre-wrap;
}

.fullHeight {
  height: 100%;
}

.icon {
  width: 100%;
  height: 100%;
}
