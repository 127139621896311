@import '../../styles//marketplace.css';

.switcherContainer {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  @media (--viewportMedium) {
    overflow-x: hidden;
    justify-content: center;
  }
}

.switcher {
  display: inline-flex;
  color: #100c20;
}

.switcher.xs {
  font-size: 12px;
  padding: 2px;
  gap: 8px;
  border-radius: 8px;
}

.switcher.sm {
  font-size: 16px;
  padding: 4px;
  gap: 8px;
  border-radius: 12px;
}

.switcher.md {
  font-size: 14px;
  padding: 4px;
  gap: 16px;
  border-radius: 16px;
}

.switcher.lg {
  font-size: 16px;
  padding: 4px;
  gap: 16px;
  border-radius: 16px;
}

.switcherButton {
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  padding: 12px 16px;
}

.switcherButton.xs {
  padding: 2px;
  border-radius: 64px;
}

.switcherButton.sm {
  padding: 8px;
  border-radius: 64px;
}

.switcherButton.md {
  padding: 8px 12px;
  border-radius: 64px;
}

.switcherButton.lg {
  padding: 12px 16px;
  border-radius: 64px;
}

.active {
  border: 1px solid #ffffff;
}
