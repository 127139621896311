@import '../../../styles/marketplace.css';

.wrapper {
  max-width: 100vw;
  padding: 25px 0px;
  background-color: #fff;
  border-radius: 64px 64px 0px 0px;
}

.container {
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    padding: 80px 35px;
    max-width: 1230px;
    margin: 0 auto;
  }
}

.title {
  /* color: var(--text-high_em, #fff); */
  color: #0C0C0C;
  text-align: center;

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.32px;
  }
}

.description {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.76);
  text-align: center;
  font-size: 16px;
  max-width: 728px;
  font-weight: 400;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 30px;
    line-height: 24px;
  }
}

.content {
  width: 100%;
  margin-top: 32px;
  display: flex;
  overflow-x: auto;
  gap: 24px;
}

.logoWrapper {
  margin-top: 40px;
  max-width: 1280px;
  width: 100%;
  white-space: nowrap;
  position: relative;
  overflow: hidden;

  @media (--viewportMedium) {
    padding-left: 60px;
    padding-right: 60px;
    position: static;
    margin-bottom: -100px;
  }
}

.slide_track {
  display: inline-block;
  animation: 15s scroll infinite linear;

  @media (--viewportMedium) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: none;
  }
}

.slide_track_copy {
  display: inline-block;
  animation: 15s scroll infinite linear;

  @media (--viewportMedium) {
    display: none;
  }
}

.slide_image {
  margin-right: 60px;
  @media (--viewportMedium) {
    margin: 0px;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
