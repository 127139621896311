@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.locationWrapper {
  margin-bottom: 40px;
}

.heading {
  padding: 0;
  margin-bottom: 16px;

  color: var(--typo-primary, #1c1c1c);

  /* CSG 20 Semibold */
  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.locationDetails {
  padding: 0;
  margin: 0;
}

.card {
  composes: marketplaceInputStyles from global;

  @media (--viewportMedium) {
    padding: 11px 0 11px 16px;
  }
}

.cardError {
  border: var(--borderErrorField);
}

.error {
  color: var(--colorFail);
  font-size: 13px;
}

.errorMessage {
  margin-top: 24px;
  color: var(--colorFail);
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;

  color: var(--typo-primary, #1c1c1c);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.saveForLaterUseLegalInfo {
  color: var(--typo-secondary, #656565);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingDetails {
  /* margin-bottom: 48px; */
}

.sameAddressCheckbox {
  display: block;
  margin: 24px 0;
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--colorGrey300);
}

.message {
  border-bottom-color: var(--colorGrey300);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 40px;
  }
}

.paymentInfo {
  composes: h5 from global;

  color: var(--colorGrey300);
  text-align: center;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    font-size: 14px;
    padding: 2px 0 6px 0;
    margin: 24px 0 0 0;
  }
}

.submitButton {
  /* margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 16px;
  } */
}

.missingStripeKey {
  color: var(--colorFail);
}

.inputContainer {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 40px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
