.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f6;
}

.screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.authContainer {
  width: 50%;
}

.authTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
}

.authFormContainer {
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.noAccessContainer {
  width: 50%;
  background-color: #fff;
  padding: 30px;
  text-align: center;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.noAccessTitle {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  padding-bottom: 12px;
}

.hasAccessScreen {
  width: 100%;
  height: 100%;
  padding: 36px;
}

.table {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  border-collapse: collapse;
  overflow: hidden;
  margin-top: 20px;
}

.table th {
  padding: 16px !important;
  color: #8c8a94;
  font-weight: 400;
  text-align: left;
}

.table td {
  padding: 16px;
  background-color: #ffffff;
  text-align: left;
  border-top: solid #f3f3f4 1px;
}

.creatorContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.creatorAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.firstRow {
  padding: 0px !important;
}

.socialMediaContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.socialMediaIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.actionContainer {
  display: flex;
  justify-content: end;
  gap: 40px;
  align-items: center;
}

.actionContent {
  min-width: 80px;
}

.openChatLink {
  color: var(--text-primary_em, #7632D4);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  cursor: pointer;
  padding: 8px;
}

.openChatLink:hover {
  text-decoration: underline;
}

.acceptedStatusText {
  color: var(--text-low_em, #828282);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.actionButtonContainer {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.actionButton {
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 20px;
  background: var(--surface-surface_1, #F7F7F6);
  border: 0;
  cursor: pointer;

  color: black;
  font-weight: 500;
}

.actionButton:hover {
  background: var(--surface-surface_2, #EDEDEB);
}

.paginationContainer {
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;
  height: 48px;
}

.applicationStatusText {
  color: var(--text-low_em, #828282);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.applicationStatusText {
  text-align: center;
  color: var(--text-low_em, #828282);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.noApplicantsText {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.btnBadge {
  font-weight: 400;
  font-size: 12px;

  background-color: #fff;
  border-radius: 2px;
  padding: 1px 4px;
  font-size: 14px;
}
