.selectWrapper {
  display: inline-block;
  position: relative;
  min-width: 200px;
}

.select {
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 12px;
  appearance: none;
  background-color: #f5f5f5;
  cursor: pointer;
}

.select:disabled {
  background-color: #f9f9f9;
  cursor: not-allowed;
}
