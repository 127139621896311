@import '../../styles/marketplace.css';
:root {
  --EditListingBackgroundAndExperienceForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}
.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.error {
  color: var(--failColor);
}

.isInfoCorrect,
.consentPayThroughProlead,
.consentProleadsPolicy,
.consentStripeAgreement,
.consentGrantProleadLicense {
  @apply --EditListingBackgroundAndExperienceForm_formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
