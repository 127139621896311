@import '../../../styles/marketplace.css';

.root {
  display: flex;
  padding: var(--radius-3xl, 48px) 24px;
  justify-content: center;
  align-self: stretch;

  border-radius: var(--radius-border_radius_64, 64px) var(--radius-border_radius_64, 64px)
    var(--radius-border_radius_0, 0px) var(--radius-border_radius_0, 0px);
  background: var(--surface-surface_0, #fff);

  @media (--viewportMedium) {
    padding: 80px 60px;
  }
}

.cardImage {
  width: 328px;
  height: 255.495px;

  @media (--viewportMedium) {
    width: 374px;
    height: 296px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @media (--viewportMedium) {
    align-items: center;
    flex-direction: row;
    gap: 60px;
  }
}

.textContainer {
  align-items: center;
  @media (--viewportMedium) {
    max-width: 726px;
    display: flex;
    flex-direction: column;
    align-items: normal;
  }
}

.title {
  color: var(--text-high_em, #0c0c0c);
  text-align: center;

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.2px;
  margin-bottom: 8px;
  /* text-align: center; */
  @media (--viewportMedium) {
    text-align: left;
    font-family: Unbounded;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: -0.32px;
    margin-bottom: 12px;
  }
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  color: #0c0c0c;
  font-weight: 700;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 32px;
    text-align: left;
  }
}

.textContent {
  color: var(--text-high_em, #0c0c0c);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;

    font-size: 18px;
    line-height: 30px;
    margin-bottom: 24px;
  }
}

.signupLink:hover {
  text-decoration: none;
}

.button {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--surface-surface_0, #292929);

  @media (--viewportMedium) {
    width: fit-content;
  }

  &:hover {
    background: #555353;
  }

  &:focus {
    background: #555353;
  }
}
