@import '../../styles/marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  padding: 0;
  /* padding: 10px; */
}

.listingCards {
  padding: 0 0px 50px 0px;
  gap: 5px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 32px 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.listingCardFourColumn {
  padding: 0 0px 50px 0px;
  gap: 5px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 32px 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.listingCard {
  /* margin-bottom: 16px; */
  display: flex;
  /* flex-basis: 49%; */

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    /* flex-basis: calc(33% - 24px); */
    /* margin-right: 24px; */
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(3n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.categorieHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.legalLink {
  color: #656565;
  text-align: center;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  gap: 10px;
  align-items: center;
}

.subheader {
  margin: 0;
  color: var(--typo-primary, #1c1c1c);

  /* ProLed 32 Heading */
  font-family: 'Bricolage Grotesque';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 137.5% */
  letter-spacing: -0.64px;
}
