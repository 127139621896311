@import '../../styles/marketplace.css';

.root {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.reviewItem {
  margin-bottom: 32px;
  flex: 1;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: column;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.avatar {
  flex-shrink: 0;
  margin-right: 12px;
}

.reviewContent {
  /* @apply --marketplaceH4FontStyles;
  font-style: italic;
  margin: 0;
  white-space: pre-line; */
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #313131;
}

.reviewInfo {
  /* @apply --marketplaceH5FontStyles;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  } */
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

/* p {
  margin: 0px;
} */
.reviewAuthor {
  display: flex;
}

.authorName,
.reviewDate {
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
}

.reviewDate {
  color: #959595;
}
