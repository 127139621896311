.root {
  border-top: 1px solid #f2f2f1;
  flex: 1 0 0;
  width: 100%;
  background-color: aliceblue;
  display: grid;
  grid-template-columns: 216px 1fr 400px;
}

.left {
  background-color: #FFF;
  height: 100%;
  overflow: auto;
}

.card {
  padding: 18px;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: start;
  height: 80px;
  border-left: 2px solid #f2f2f1;
  background-color: #f2f2f1;
}

.cardSelected {
  background-color: #FFF;
  border-left: 2px solid #dd58d6;
}

.cardContent {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
}

.right {
  background-color: #f2f2f1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatBanner {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  height: 80px;
}

.chatInputContainer {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  background-color: #FFF;
  min-height: 64px;
}

.messageInput {
  resize: none;
  min-height: 40px;
  height: 40px;
  overflow: auto;
  overflow-wrap: break-word;

  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */

  padding: 10px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  border: 1px solid var(--outline-low_em, #EEEEED);
  background: var(--surface-surface_0, #F2F2F1);
}

.messageInput:hover {
  border: 1px solid var(--outline-med_em, #EEEEED);
}

.messageInput:focus-within {
  border: 1px solid var(--outline-high_em, #EEEEED);
}

.attachButton {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #f2f2f1;
  border: 0px;
}

.attachButton:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.sendButton {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #000000;
  border: 0px;
}

.sendButton:hover {
  background-color: #3b3b3b;
  cursor: pointer;
}

.chatButtonIcon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.chatBannerContent {
  display: flex;
  gap: 12px;
  align-items: start;
}

.flexGrow {
  flex: 1 0 0;
}

.chatContainer {
  flex: 1 0 0;
  padding: 8px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bubble {
  position: relative;
  padding: 12px;
  border-radius: 12px;
  background-color: #FFF;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 80%;

  font-weight: 400;
  font-family: Afacad;
  font-size: 16px;
  line-height: 24px;
}

.bubbleRight {
  display: flex;
  justify-content: end;
}

.bubbleLeft {
  display: flex;
  justify-content: start;
}

.bubbleCreatedAt {
  position: absolute;
  top: 8px;
  right: 8px;
  text-align: end;

  font-family: Afacad;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #434343;
}

.banner {
  background-color: #FFF;
  padding: 32px;
}

.bannerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.providerAvatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
}

.providerName {
  font-family: Afacad;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #0c0c0c;

  margin-top: 12px;
}

.buttonContainer {
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.noApplicants {
  text-align: center;
}
