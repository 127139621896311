.tabContainer {
  display: flex;
  align-items: center;
}

.tabTitle {
  margin-left: 16px;
  transition: 0.3s;
}

.hidden {
  opacity: 0;
  width: 0px;
  margin-left: 0px;
}

.unreadMessagesBubble {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f4;
  border-radius: 6px;
  margin-left: 5px;
  padding: 4px 6px;

  color: var(--text-med_em, #494656);
  font-family: Afacad;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
}
