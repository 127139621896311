@import '../../styles/marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.wrapperMain {
  max-width: 1260px;
  margin: 0 auto;
  padding: 32px;
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 8px;
  color: var(--color-gray-900, #1c1c1c);

  /* CSG 24 Heading */
  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 158.333% */

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.subtitle {
  position: relative;
  top: -10px;
  margin-bottom: 20px;
  margin-top: 8px;
  @media (--viewportMedium) {
    top: -50px;
    margin-bottom: 0px;
  }
}

.image {
  max-width: 100%;
}

.sectionTitle {
  font-size: 20px;
  line-height: 24px;
  width: 700;
  margin-top: 32px;
  margin-bottom: 16px;
  color: #1c1c1c;
  font-weight: 700;
}

.info {
  max-width: 400px;
  margin: 0;
}

.copyLinkButton {
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:active {
    background-color: #c35fed;
  }
}

.listItem {
  margin-bottom: 15px;
}

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.searchButton {
  @apply --marketplaceButtonStyles;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

/* .listItem {
  margin: 30px 0;
} */

.editLinkDesktop {
  /* @apply --marketplaceLinkStyles; */
  margin: 10px 0;
  cursor: pointer;
  color: black;
}

.editLinkDesktop:hover {
  text-decoration: none;
}

.active {
  color: var(--marketplaceColorLight);
  text-decoration: underline;
}

.category {
  @media (--viewportLarge) {
    margin-bottom: 40px;
  }
}

.subheader {
  font-size: 28px;
}

.bookDemoContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bookDemoHeader {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  margin: 0;
}

.bookDemoContent {
}

.bookDemoLink {
  @apply --marketplaceButtonStyles;
  width: 200px;
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.table {
  width: 100%;
  border-radius: var(--radius-m, 16px);
  background: var(--White, #fff);

  /* shadow-m */
  box-shadow: 0px 1px 16px 0px rgba(50, 50, 71, 0.1), 0px 0px 2px 0px rgba(12, 26, 75, 0.18);
}

.table th {
  padding: 16px;
  text-align: left;
  color: var(--typo-primary, #1c1c1c);

  /* CSG 16 Semibold */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  border-bottom: 1px solid var(--Gray-200, #eaecf0);

  @media (--viewportMedium) {
    padding: var(--spacing-3, 16px) 24px;
  }
}

.table td {
  padding: 16px;

  @media (--viewportMedium) {
    padding: var(--spacing-3, 16px) 24px;
  }
}

.table tr:not(:last-child) td {
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
}

.tableClient {
  display: flex;
  gap: 16px;
  align-items: center;
}

.tabelClientContent {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.tabelClientContentName {
  color: var(--typo-primary, #1c1c1c);

  /* CSG 16 Semibold */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  margin: 0;
}

.tabelClientContentType {
  color: var(--typo-secondary, #656565);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin: 0;
}

.tableLocation {
  color: var(--typo-primary, #1c1c1c);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.inProgress {
  padding: 4px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  background: var(--badge-success-fill, rgba(45, 199, 109, 0.1));
  color: var(--badge-success-text, #2dc76d);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  width: fit-content;
}

.declined {
  padding: 4px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  background: var(--badge-error-text, rgba(255, 112, 82, 0.14));

  color: var(--badge-error-fill, #ff7052);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  width: fit-content;
}

.requested {
  padding: 4px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  background: var(--badge-warning-fill, rgba(254, 220, 0, 0.2));
  color: var(--badge-warning-text, #f0bd05);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  width: fit-content;
}

.completed {
  padding: 4px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 8px);
  background: var(--badge-neutral-text, rgba(101, 101, 101, 0.08));

  color: var(--badge-neutral-fill, #656565);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  width: fit-content;
}

.enquiry,
.chatLink {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.pinkButton {
  /* color: #dd58d6; */
  text-align: center;
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  white-space: nowrap;
}

.marketplaceButton {
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f3f3f4;
  border: none;
  font-size: 14px;
  color: #100c20;
  font-weight: 700;

  &:active {
    text-decoration: none;
  }
}

.tableSearch {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.rowNotificationDot {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 13px;
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  width: 100%;
  height: 100%;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;

  @media (--viewportLarge) {
    width: 9px;
    height: 9px;
  }
}

.buttonLink:hover {
  text-decoration: none;
}

.arrowIcon {
  cursor: pointer;
}

.unreadMessagesBubble {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f4;
  border-radius: 6px;
  margin-left: 5px;
  padding: 4px 6px;

  color: var(--text-med_em, #494656);
  font-family: Afacad;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
}

/* switcher */
.switcherContainer {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 24px;
}

.switcher {
  /* border-top-left-radius: ; */
  background-color: #f6f6f6;
  font-size: 12px;
  transition: 0.3s;
  display: flex;
  overflow: hidden;
}

.switcherButton {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid var(--border-default, #ddd);
  cursor: pointer;
  color: #959595;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  text-decoration: none;
}

.active {
  background-color: #fff;
  color: #1c1c1c;
  border-radius: var(--radius-s, 8px) var(--radius-s, 8px) 0px 0px;
  border-bottom: 2px solid var(--color-ContentSG-purple-500, #dd58d6);
  background: var(--badge-brand1-fill, rgba(244, 122, 244, 0.16));
  color: var(--color-ContentSG-purple-500, #dd58d6);
  text-align: center;

  /* CSG 16 Semibold */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}
