@import '../../../styles/marketplace.css';

.container {
  padding: var(--radius-3xl, 48px) 24px;
  max-width: 100vw;
  @media (--viewportMedium) {
    padding: 80px 35px;
    max-width: 1280px;
    margin: 0 auto;
  }
}

.title {
  color: var(--text-high_em, #fff);
  text-align: center;

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.32px;
  }
}
