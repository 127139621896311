@import '../../../styles/marketplace.css';

.sortContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.sortCounter {
  color: #959595;
  text-align: center;

  /* ProLed 16 Regular */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.sortLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.sortLabel span {
  color: #1c1c1c;
  text-align: center;

  /* ProLed 16 Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.sortContent {
  padding: 8px 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 20px 0px rgba(50, 50, 71, 0.1), 0px 0px 2px 0px rgba(12, 26, 75, 0.24);
}

.sortItem {
  cursor: pointer;
  display: flex;
  width: 180px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  color: var(--typo-primary, #1c1c1c);

  /* ProLed 16 Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
