@import '../../styles/marketplace.css';

.root {
}

.input {
  /* border-bottom-color: var(--attentionColor); */
  border: 1px solid #ddd;
  border-radius: 8px;
  color: var(--matterColor);
  padding: 9px 16px;
  font-size: 16px;

  /* &:hover {
    border-bottom-color: #ddd;
  } */

  /* &:focused {

  } */
}

.label {
  font-size: 16px;
}
/* .inputSuccess {
  border-color: var(--successColor);
} */

.inputError {
  border-color: var(--failColor);
}

.textarea {
}
