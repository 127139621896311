@import '../../styles/marketplace.css';

p {
  margin: 0;
}
.root {
  /* Layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);
  left: 0px;
  transition: 0.5s;
  position: sticky;

  z-index: 1000;
  top: 0;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
  transition: width 0.3s ease;
  width: 92px;
  padding: 24px;
  border-right: 1px solid #d9d9d9;

  /*  */
  flex-direction: column;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
  background-color: white;
  border-right: 1px solid #d9d9d9;

  @media (--viewportLarge) {
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: white;
    border-right: 1px solid #d9d9d9;
  }
}

.sidebarWrapper {
  display: none;

  @media (--viewportMedium) {
    display: block;
    width: 92px;
    transition: 0.3s;
  }
}

.pinned {
  width: 280px;
}

.hovered {
  width: 280px;
}

.tabs {
  display: flex;
  overflow-x: auto;

  min-height: auto;
  flex-direction: column;
  margin-top: 24px;
  padding-top: 0;
  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  overflow: hidden;

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
