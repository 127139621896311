@import '../../../styles/marketplace.css';

.white_bg {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.root_howitworks {
  display: flex;
  padding: var(--radius-3xl, 48px) 24px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-5, 32px);
  align-self: stretch;

  border-radius: var(--radius-border_radius_64, 64px) var(--radius-border_radius_64, 64px)
    var(--radius-border_radius_0, 0px) var(--radius-border_radius_0, 0px);
  background: var(--surface-surface_0, #292929);

  margin-bottom: -1px;

  @media (--viewportMedium) {
    padding: 80px 60px;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-5, 32px);
  }
}

.rootContent {
  max-width: 1160px;
}

.title {
  color: var(--text-high_em, #fff);
  text-align: center;

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.32px;
  }
}

.subtitle {
  margin-top: 8px;

  color: var(--text-med_em, rgba(255, 255, 255, 0.76));
  text-align: center;

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 30px;
  }
}

.card_list_container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-5, 32px);
  align-self: stretch;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: var(--spacing-5, 32px);
  }
}

.card {
  display: flex;
  height: 260px;
  padding: var(--spacing-5, 32px);
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;

  border-radius: var(--radius-border_radius_32, 32px);
  background: var(--surface-white, #fff);

  @media (--viewportMedium) {
    height: 300px;
    flex: 1 0 0;
  }
}

.cardTitle {
  align-self: stretch;
  color: var(--text-high_em, #8338ec);

  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.16px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 32px;
  }
}

.orangeTiltle {
  color: #f1582f;
}

.cardSubtitle {
  align-self: stretch;
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (--viewportMedium) {
    line-height: 30px;
  }
}

.card_number {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: var(--radius-border_radius_100, 100px);
  background: var(--color-brand-gray-50, #f3f4e4);

  color: var(--text-high_em, #131313);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.textContent {
  @media (--viewportMedium) {
    min-height: 130px;
  }
}
