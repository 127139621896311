@import '../../styles/customMediaQueries.css';

/* Dummy Topbar */

.topbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
    justify-content: center;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /* padding: 0 48px; */
  }
}

.orderFormContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-grow: 0;
    /* flex-basis: 519px;
    margin-top: 73px;
    margin-right: 132px; */
  }
}

.listingImageMobile {
  position: relative;
  background-color: var(--colorGrey100); /* Loading BG color */

  @media (--viewportLarge) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.headingContainer {
  margin-top: 0px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    margin-top: 00px;
    margin-bottom: 40px;
  }
}

.heading {
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 8px;
  color: var(--color-gray-900, #1c1c1c);

  /* CSG 24 Heading */
  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 158.333% */

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.subtitle {
  color: var(--typo-primary, #1c1c1c);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin: 0px;
}

.listingDetailsWrapper {
  /* margin: 0 48px; */
}

.detailsHeadingMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.author {
  width: 100%;
  composes: h4 from global;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--colorGrey300);

  margin-top: 0;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 120px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
    padding: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  color: var(--colorFail);
}
.notFoundError {
  color: var(--colorAttention);
}

.speculateError {
  color: var(--colorFail);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}

.paymentForm {
  flex-grow: 1;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 409px;
    padding-bottom: 36px;

    /* Position */
    /* margin: 56px 0 24px 0; */

    /* Coloring */
    background-color: var(--colorWhite);
    border: 1px solid var(--colorGrey100);
    border-radius: 2px;
  }
}

.detailsAspectWrapper {
  position: relative;
  background-color: var(--colorGrey100); /* Loading BG color */
}

.detailsHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0;
  }
}

.detailsSubtitle {
  composes: h5 from global;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    padding: 3px 0 5px 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderBreakdownHeader {
  @media (--viewportLarge) {
    /* margin: 0 48px; */
  }
}
.orderBreakdownTitle {
  /* Font */
  font-weight: 700;

  /* Layout spacing */
  margin: 5px 24px 25px 24px;

  @media (--viewportLarge) {
    padding: 6px 0 2px 0;
    margin: 16px 0 8px 0;
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--colorGrey100);

  @media (--viewportMedium) {
    margin: 7px 0 0 0;
  }
}

.orderBreakdown {
  font-size: 16px;
  @media (--viewportLarge) {
    padding-bottom: 20px;
    /* margin: 0 48px; */
  }
}

/* ChekcoutPageWithInquiryProcess */

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.sectionHeading {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin: 0 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 0 0;
    padding-top: 5px;
    padding-bottom: 3px;
  }
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.fieldInquiryMessage {
  margin: 0 0 24px 0;

  @media (--viewportMedium) {
    margin: 16px 0 24px 0;
  }
}

.error {
  color: var(--colorFail);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.price {
  composes: p from global;
  font-weight: var(--fontWeightBold);

  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: p from global;
  font-weight: var(--fontWeightBold);

  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.inquiryPrice {
  composes: p from global;
  font-weight: var(--fontWeightBold);
}

.breakdownRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.key {
  color: #656565;
}

.value {
  color: #1c1c1c;
}

.horizontalRule {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ddd;
  margin-top: 16px;
  margin-bottom: 16px;
}

.subTitle {
  font-weight: 700;
  color: #1c1c1c;
  margin-bottom: 16px;
}

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.wrapperMain {
  max-width: 1260px;
  margin: 0 auto;
  padding: 32px;
}

.breadcrumbs {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 40px;
}

.breadcrumbGray {
  color: var(--typo-secondary, #656565);

  /* CSG 20 Regular */
  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */

  &:hover {
    text-decoration: none;
  }
}

.breadcrumbCurrent {
  color: var(--typo-primary, #1c1c1c);

  /* CSG 20 Semibold */
  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
