@import '../../styles/marketplace.css';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.title {
  color: #100c20;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
}

.filters {
  display: flex;
  gap: 8px;
  align-items: center;
}

.filterTitle {
  flex-shrink: 0;
}

.divider {
  width: 1px;
  height: 24px;
  background-color: #ececed;
  margin: 0 12px;
}

.periodSelector {
  font-size: 16px;
  padding: 8px 24px 8px 12px;
  background: #f8f8f8
    url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.5rem) center !important;
  border-radius: 8px;
}

.emptyRow {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px 24px;
  align-self: stretch;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: #f8f8f8;
  color: #100c20;
}

.followersCard {
  grid-column: span 2;

  @media (max-width: 768px) {
    grid-column: span 1;
  }
}

.cardTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  width: 100%;
}

.regionCardTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageSelector {
  display: flex;
  align-items: center;
}

.pageButton {
  background: #ffffff;
  border: 1px solid #ececed;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pageButton:hover {
  background: #f8f8f8;
}

.pageButton[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.pageArrow {
  width: 6px;
}

.pageArrow path {
  fill: #100c20;
}

.cardValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.absoluteValue {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
  font-family: Unbounded;
}

.changeRate {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  flex-wrap: wrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.regionCard {
  grid-column: span 2;

  @media (max-width: 768px) {
    grid-column: span 3;
  }
}

.channelCard {
  grid-column: span 3;
}

.regionRow {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin-bottom: 16px;
}

.countryRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.progressBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 80px;
  background: none;
  background-color: #ececed;
}

.progressBarFilled {
  display: flex;
  background: var(--marketplaceColor);
  border-radius: 80px;
  height: 8px;
}
