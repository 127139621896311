@import '../../styles/marketplace.css';

.root {
  @apply --marketplaceButtonStyles;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryButtonRoot {
  @apply --marketplaceButtonStylesPrimary;
  padding: 8px 16px;
  background: var(--surface-surface_high_em, #292929);
}

.primaryButtonRoot:hover {
  background: var(--surface-surface_1, #1c1c1c);
}

.secondaryButtonRoot {
  @apply --marketplaceButtonStylesSecondary;
}

.inlineTextButtonRoot {
  @apply --marketplaceLinkStyles;
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}

.inProgress {
  /* padding: 0; */

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  /* background-color: var(--marketplaceColor); */
  background-color: #1c1c1c;
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  /* padding: 0; */

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  /* background-color: var(--marketplaceColor); */
  background-color: #1c1c1c;
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

.socialButton {
  background-color: #fff;
  width: 100%;
  padding: 12px 32px;
  font-size: 18px;
  line-height: 24px;
  border: none;
  border-radius: var(--radius-s, 8px);
  background: var(--action-secondary-default, #f3f3f3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  gap: 8px;
}

.grayButton {
  border-radius: 8px;
  background: #313131;
  color: #fff;

  text-align: center;
  /* ProLed 16 Semibold */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  outline: inherit;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.pinkButton {
  border-radius: 48px;
  border: 1px solid #292929;
  background: #292929;
  color: #fff;
  padding: 8px 20px;
  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.blackButton {
  border-radius: 48px;
  border: 1px solid #1c1c1c;
  background: #1c1c1c;
  color: #fff;
  padding: 12px 16px;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  cursor: pointer;
}

.blackButton:disabled {
  cursor: not-allowed;
  background-color: #e2e2e2;
  border: 0;
}

/* New Button Color for HomePage */
.softBlackButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 64px;
  background: #3f3244;
  border: 1px solid #3f3244;
  color: #fff;
  padding: 12px 16px;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  height: 48px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
}

.softBlackOutlinedButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 64px;
  background: #fff;
  border: 1px solid #3f3244;
  color: #3f3244;
  padding: 12px 16px;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  height: 48px;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
  }
}

.secondaryPinkButton {
  border-radius: var(--radius-3xl, 48px);
  border: 1px solid var(--color-ContentSG-purple-500, #292929);
  background: var(--Neutral-1, #fff);
  color: var(--color-ContentSG-purple-500, #292929);
  text-align: center;
  padding: var(--spacing-2, 8px) 20px;
  cursor: pointer;

  /* CSG 20 Semibold */
  font-family: Afacad;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2, 8px);
}

.whiteButton {
  display: block;
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  color: #100c20;
  border: 1px solid #ececed;

  text-align: center;
  /* ProLed 16 Semibold */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 8px 16px;
  cursor: pointer;
}

.tertiaryButton {
  display: block;
  width: 100%;
  border-radius: 8px;
  background: #f8f8f8;
  color: #131313;

  text-align: center;
  /* ProLed 16 Semibold */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 8px;
  border: 1px solid #ddd;
  cursor: pointer;
}
