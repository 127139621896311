.fileInput {
  height: 50px;
  width: 100%;
  border-radius: 15px;
  padding: 50px 20px;
  border: 1px solid #d1d1d1;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.fileInput:hover {
  background-color: #f3f3f4;
}

.previewContainer {
  height: 250px;
  width: 100%;
  padding: 8px 0px;
}

.chooseAnotherBtn {
  background-color: #dbdbdb;
  border: 0;
  color: #2b2b2b;
  padding: 5px 16px;
  font-weight: 500;
}

.chooseAnotherBtn:disabled {
  background-color: #e2e2e2;
  color: #a0a0a0;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.previewVideo {
  width: 100%;
  height: 100%;
}

.previewFile {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  background-color: #f3f3f4;
}

.fileIcon {
  width: 100px;
}

.uploadedContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
}

.loadingContainer {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  text-align: center;
  color: #f62a2a;
}

.btnText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}
