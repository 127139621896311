@import '../../styles/marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    /* padding-top: 10px; */
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
  min-height: calc(100vh - var(--topbarHeightDesktop));

  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0;
    /* margin-bottom: 21px; */
  }

  @media (--viewportLarge) {
    padding: 50px 0;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.searchListingsPanelWithoutPaddings {
  flex-grow: 1;
  padding: 0;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.authenticatedUserMapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 25.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}
/*  */
.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: var(--SearchPage_containerHeight);
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.authenticatedUserMap {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: var(--SearchPage_containerHeight);
  }

  @media (--viewportLarge) {
    width: 25.5vw;
  }
}

.sideNav {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.wrapperMain {
  max-width: 1260px;
  margin: 0 auto;

  padding: 32px 6px 32px 6px;

  @media (--viewportMedium) {
    padding: 40px;
  }
}

/*Search */
.searchLink {
  display: none;

  @media (--viewportLarge) {
    flex-grow: 1;
    height: 100%;
    margin-right: 24px;
    border-radius: 20px;
    border: 2px solid black;
    height: 50px;
    width: 100%;
    display: block;
  }
}

.searchMobileLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-radius: 20px;
  border: 2px solid black;
  height: 50px;
  width: 100%;

  @media (--viewportLarge) {
    display: none;
  }
}

.topbarSearchWithLeftPadding {
  height: 50px;
  padding-left: 24px;

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

.buttonsContainer {
  margin-top: var(--fontSize);
  display: flex;
  justify-content: space-around;
}

.searchContainer {
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 50px;
  }
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.categoryTitle {
  color: #1c1c1c;

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 158.333% */
  margin-bottom: 8px;
  margin-top: 0;
}

.activityLabel {
  color: #1c1c1c;

  /* CSG 16 Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin-bottom: 16px;
}

.activityList {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px var(--spacing-3, 16px);
  align-self: stretch;
  flex-wrap: wrap;
}

.activity {
  padding: 8px 16px;
  border-radius: 40px;
  background: #f6f6f6;
  color: #1c1c1c;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  cursor: pointer;
}

.divider {
  background: #ddd;
  height: 1px;
  width: 100%;
  border: 0;
  margin: 32px 0;

  @media (--viewportMedium) {
    margin: 40px 0;
  }
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 16px 16px;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (--viewportLarge) {
    margin-bottom: 16px;
  }
}

.categoriesBar {
  position: sticky;
  top: 0;
  width: calc(100% + 80px);
  background-color: #fff;
  z-index: 10;
  margin-top: -40px;
  margin-left: -40px;
  margin-bottom: 40px;
}

.categoryDesc {
  color: var(--typo-secondary, #656565);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin: 0;
}

.categoryDescLink {
  color: var(--color-ContentSG-purple-500, #dd58d6);
  cursor: pointer;
}

.search {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #ddd;
  width: 100%;
  margin-top: 24px;
}

.search form {
  flex: 1;
}

.search input {
  padding: 0;
  border-bottom: none;
}

.filterList {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filterItem {
  display: flex;
  gap: 8px;
  padding: 4px 8px 4px 16px;
  background-color: rgba(101, 101, 101, 0.08);
  align-items: center;
  border-radius: 8px;
}

.filterItem span {
  color: var(--badge-neutral-fill, #656565);

  /* CSG 16 Regular */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.filterItem svg {
  cursor: pointer;
}
