@import '../../../styles/marketplace.css';

.desktopToolbarContainer {
  display: none;
  justify-content: center;

  @media (--viewportMedium) {
    display: flex;
  }
}

.bgWhite {
  background-color: var(--matterColorLight);
}

.bgDark {
  background-color: #292929;
}

.textWhite {
  color: #fff;
}

.desktopToolbar {
  max-width: 1280px;
  width: 100%;
  /* height: auto; */
  display: flex;
  padding: 12px 32px;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1440px) {
    padding: 12px 64px;
  }
}

.logo {
  width: 127px;
  height: auto;
}

.searchContainer {
  display: flex;
  width: 300px;
  height: 48px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 48px;
  border: 1px solid #e2e2e4;

  @media (min-width: 1440px) {
    width: 300px;
  }
}

.searchContainer form {
  flex: 1;
}

.searchContainer input {
  padding: 0;
  border-bottom: none;
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #8c8a94;
}

.navbar,
.navbarButtons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.navbar {
  gap: 52px;
}

.navigationLink {
  font-family: Afacad;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  display: inline-block;
  white-space: nowrap;
  transition: 0.2s;
  height: 32px;

  /* Font */
  /* @apply --marketplaceTabNavFontStyles; */

  color: #3f3244;

  /* push tabs against bottom of tab bar */
  margin-top: 0;
  transition: var(--transitionStyleButton);
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  cursor: pointer;
}

/* .logInLink {
  padding: 0;
} */

.signupLink {
  &:hover {
    text-decoration: none;
  }
}

.logOutButton {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  height: fit-content;
}

.navigationLinkWhite {
  font-family: Afacad;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  display: inline-block;
  white-space: nowrap;
  transition: 0.2s;
  height: 32px;

  /* Font */
  /* @apply --marketplaceTabNavFontStyles; */

  color: #fff;

  /* push tabs against bottom of tab bar */
  margin-top: 0;
  transition: var(--transitionStyleButton);
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  cursor: pointer;
}

.navigationLinkActive {
  color: #0f69e0;
}

.btn_signup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
  padding: 8px 16px;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--text-white, #fff);
  border: 1px solid #292929;

  color: var(--color-dark-700, #292929);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  cursor: pointer;

  @media (--viewportMedium) {
    width: fit-content;
    white-space: nowrap;
  }

  &:hover {
    background-color: #d8d8d2;
    border: 1px solid #d8d8d2;
    text-decoration: none;
  }
}

.btn_login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 36px;
  padding: 8px 16px;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 1px solid var(--surface-white, #fff);

  color: var(--surface-white, #fff);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  @media (--viewportMedium) {
    width: fit-content;
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
  }
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.brand_btn_login {
  display: flex;
  height: 36px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 1px solid var(--surface-neutral_alt_black, #292929);
  background: var(--surface-white, #fff);

  color: var(--color-dark-700, #292929);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.brand_btn_signup {
  display: flex;
  height: 36px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: var(--radius-border_radius_64, 64px);
  background: var(--surface-neutral_alt_black, #292929);
  border: 1px solid var(--surface-neutral_alt_black, #292929);

  color: var(--surface-white, #fff);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.underlined {
  text-decoration: underline;
  text-underline-offset: 8px;

  &:hover {
    text-decoration: underline;
  }
}
