.messageText {
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  white-space: nowrap;
}

.btnContainer {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
