.notice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  border: 1px solid var(--color-ContentSG-purple-500, #dd58d6);
  padding: 12px 24px;
  border-radius: 20px;
  color: #100c20;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.leading {
  display: flex;
  align-items: center;
}

.trailing {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 12px;
    width: 100%;
  }
}

.infoIcon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
  color: var(--color-ContentSG-purple-500, #dd58d6);
}

.divider {
  width: 1px;
  height: 24px;
  background-color: #ececed;
  margin: 0 12px;

  @media (max-width: 768px) {
    display: none;
  }
}
