
.btnSubmit {
  margin-top: 18px;
}

.btnContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.headerText {
  font-size: 20px;
  font-weight: 500;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
  margin-top: 8px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}
