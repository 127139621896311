.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.caseCard {
  margin-bottom: 10px;
  
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.caseCard:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.caseTitle {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.btnCreate {
  border-radius: 10px;
  padding: 6px;
  border: 1px solid black;
  color: black;
  cursor: pointer;
}

.btnCreate:hover {
  background-color: black;
  color: white;
}

.statusPill {
  width: fit-content;
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
}

.statusDraft {
  border: 1px solid #121212;
}

.statusPublished {
  border: 1px solid #DD58D6;
  background-color: #DD58D6;
  color: white;
}