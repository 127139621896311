.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.modalContent {
  margin-top: 12px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Afacad';
  line-height: 32px;
  color: #0C0C0C;
}

.children {
  margin-top: 24px;
}
