@import '../../styles/marketplace.css';

.root {
  background-color: var(--matterColorLight);
}

.wrapperMain {
  max-width: 1260px;
  margin: 0 auto;
  padding: 32px;
  overflow: hidden;
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.switcher {
  border-radius: 8px;
  border: solid 1px #ddd;
  background-color: #f6f6f6;
  font-size: 12px;
  transition: 0.3s;
  display: flex;
}

.switcherButton {
  padding: 8px 16px;
  background-color: #f9fafb;
  border: none;
  cursor: pointer;
  color: #959595;
  font-size: 16px;
  line-height: 24px;
  border: solid 1px #ddd;
  text-wrap: nowrap;
  &:first-child {
    border-radius: 8px 0 0 8px;
    border: 1px solid #ddd;
  }
  &:last-child {
    border-radius: 0px 8px 8px 0px;
    border: 1px solid #ddd;
  }

  &:hover {
    text-decoration: none;
  }
}

.active {
  background-color: #fff;
  color: #1c1c1c;
}

.switcherContainer {
  overflow-x: scroll;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  @media (--viewportMedium) {
    overflow-x: unset;
  }
}

.header {
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.6px;
  color: #1c1c1c;
  margin: 0 0 24px 0;
}

.subheader {
  font-size: 20px;
  line-height: 30px;
  color: #1c1c1c;
  margin: 0 0 4px 0;
  text-align: left;
}

.form {
  max-width: 526px;
}

.horizontalRule {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ddd;
  margin-top: 22px;
}
