.or_wrapper_container {
    position: relative;
    margin: 25px 0 10px;
  }
  
  p.or_wrapper {
    position: absolute;
    top: -33px;
    left: calc(50% - 15px);
    padding: 0 15px;
    background: #fcfcfc;
    font-size: 18px;
  }