.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
