@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.input {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.unitTypeHidden {
  display: none;
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 0px;
  margin-top: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
    margin-bottom: 24px;
  }
}

.buttonContainer {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  gap: 8px;

  @media (--viewportLarge) {
    gap: 16px;
    flex-direction: row;
  }
}

.buttonContainer a {
  @media (--viewportLarge) {
    margin-top: 77px;
  }
}

.addOnsLabel {
  font-size: 16px;
}

.addOnsDesc {
  margin: 0;
  color: var(--typo-secondary, #656565);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 16px;
}

.addOnsItem {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
}

.addOnsInput {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  align-items: center;
  position: relative;
}

.addOnsCurrency {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%);
}

.addOnsInput svg {
  cursor: pointer;
}

.addOnsInput input {
  padding: 9px 16px 9px 48px !important;
}

.addOnsItem input {
  border: 1px solid #ddd;
  border-radius: 8px;
  color: var(--matterColor);
  padding: 9px 16px;
  font-size: 16px;
}

.addOnsButton {
  color: var(--color-ContentSG-purple-500, #dd58d6);
  /* CSG 16 Semibold */
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  padding: 0;
  background-color: transparent;
  border: none;
  width: fit-content;
  margin-bottom: 8px;
  cursor: pointer;
}

.addOnsError {
  color: #f00;
}
