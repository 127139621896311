
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      
      margin-top: 17px;
      margin-bottom: 15px;
    }
  