.wrapperMain {
  padding: 0px;
  background-color: #f8f7f5;
}

.content {
  flex: 1 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left {
  display: flex;
  height: 100%;
  padding: var(--spacing-6, 40px) var(--spacing-5, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;

  background: var(--surface-surface_0, #FFF);
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.headerTitle {
  color: var(--text-high_em, #0C0C0C);
  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  letter-spacing: -0.24px;
}

.headerSubtitle {
  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.paymentMethodContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sectionTitle {
  color: var(--text-high_em, #0C0C0C);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.paymentMethodSelect {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}

.paymentMethodSelectItem {
  display: flex;
  height: 72px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_16, 16px);
  border: 1px solid var(--outline-med_em, #EAEAE8);
  background: var(--surface-surface_0, #FFF);
  cursor: pointer;
}

.selectedItem {
  background: var(--surface-surface_0, #eeeeee);
}

.cardPaymentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputText {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: var(--radius-border_radius_12, 12px);
  border: 0px solid var(--outline-med_em, #EAEAE8);
  background: var(--surface-surface_2, #F2F2F1);

  color: var(--text-disabled, #A4A4A2);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.saveCardContainer {
  width: 100%;
  display: flex;
  gap: 8px;
}

.w_100 {
  width: 100%;
}

.gridTwoColumns {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.saveCardContainer {
  width: 100%;
  display: flex;
  align-items: start;
  gap: 8px;
}

.checkbox {
  padding-top: 3px;
}

.saveCardTextContainer {
  flex: 1 0 0;
}

.saveCardTitle {
  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
}

.saveCardSubtitle {
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

/* Right Section */
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  background: var(--surface-surface_bg, #F7F7F6);
}

.summaryContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 20px;
  background: #FFF;
  margin: 90px;
}

.summaryTextContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  color: var(--text-high_em, #0C0C0C);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.textRight {
  text-align: right;
}

.textSGD {
  color: var(--text-high_em, #0C0C0C);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

hr {
  border: 1px solid var(--outline-low_em, #EAEAE8);
  width: 100%;
}


.footer {
  height: 84px;
  border-top: 1px solid var(--outline-low_em, #EEEEED);
  background: var(--surface-surface_0, #FFF);
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 24px 80px;
}

.footerButton {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: var(--radius-border_radius_64, 64px);
  border-bottom: 1px solid var(--color-black-12, rgba(0, 0, 0, 0.12));
  background: var(--surface-surface_high_em, #292929);

  color: var(--text-white, #FFF);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: 0px;
}
