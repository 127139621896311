.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.right {
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.headlineContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.headlineValue {
  padding: 2px;
  font-size: 3rem;
}

.labelTitle {
  font-size: 16px;
  font-weight: 400;
}

.labelValue {
  font-size: 20px;
  font-weight: 700;
}
