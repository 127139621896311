.uploadForm {
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 24px;
  color: #4a4a4a;
}

.buttonContainer {
  display: flex;
  justify-content: end;
}

.submitButton {
  margin-top: 42px;
  width: 100%;
  /* width: fit-content; */
}

.spinner {
  stroke: #ffffff;
}
