.positionRelative {
  position: relative;
}

.imageMessage {
  max-width: 100%;
  min-height: 100px;
}

.errorMessage {
  color: #f62a2a;
}

.previewFile {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  background-color: #f3f3f4;
}

.fileIcon {
  width: 100px;
}

.downloadIconContainer {
  width: min-content;
  background-color: rgba(223, 223, 223, 0.3); /* 50% opacity */
  padding: 2px;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.downloadIcon {
  width: 20px;
  height: 20px;
}
