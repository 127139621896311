
    
    position: fixed;
    top: 0;
    right: 0;

    
    z-index: calc(var(--zIndexModal) + 1);

    
    display: flex;
    align-items: flex-start;
    width: auto;

    
    padding: 24px;
    margin: 0;
    border: 0;

    @media (--viewportMedium) {
      padding: 27px 30px;
      position: absolute;
    }

    
    background-color: transparent;
    color: var(--matterColorDark);

    cursor: pointer;

    &:enabled:hover {
      background-color: transparent;
      color: var(--marketplaceColor);
      box-shadow: none;
      text-decoration: none;
    }
    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    }
    &:disabled {
      background-color: transparent;
    }
  