.baseFont {
  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.baseContainer {
  height: 40px;
  padding: 0px var(--spacing-3, 16px);
  border-radius: var(--radius-s, 12px);
  border: 1px solid var(--outline-low_em, #EEEEED);
  background: var(--surface-surface_0, #F2F2F1);
}

.baseContainer:hover {
  border: 1px solid var(--outline-med_em, #EEEEED);
}

.baseContainer:focus-within {
  border: 1px solid var(--outline-high_em, #EEEEED);
}

.errorMessage {
  color: var(--typo-primary, #f35454);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
