
    font-family: 'Afacad', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;
    
    margin-top: 9.5px;
    margin-bottom: 8.5px;

    @media (--viewportMedium) {
      line-height: 16px;
      
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
  