.wrapperMain {
  margin: 0 auto;
  padding: 32px 0px 0px 0px;
  background-color: #f8f7f5;
  height: 100vh;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  @media (--viewportMedium) {
    margin: 0px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerActionContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.campaignNameText {
  color: var(--color-gray-900, #1C1C1C);

  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  letter-spacing: -0.24px;
}

.overviewContainer {
  padding: 0px 32px 32px 32px;
  display: flex;
  gap: 24px;
}

.overviewContainer :nth-child(1) {
  flex: 1;
}

.addMoreCreatorsContainer {
  width: 400px;
  height: min-content;
  border-radius: 20px;
  overflow: hidden;
}

.actionButtonContainer {
  position: relative;
}

.actionButtonMenu {
  position: absolute;
  width: max-content;
  right: 0;
  top: -80px;
  width: 121px;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;

  border-radius: var(--radius-border_radius_16, 16px);
  background: #FFF;
}

.actionButtonMenuItem {
  padding: 8px 16px;
  color: var(--text-high_em, #0C0C0C);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  white-space: nowrap;
}

.actionButtonMenuItem:hover {
  cursor: pointer;
  background: var(--surface-surface_1, #ebebeb);
}

.actionButton {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 1px solid var(--outline-low_em, #EEEEED);
  background: var(--surface-surface_0, #FFF);

  color: var(--text-high_em, #0C0C0C);

  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */

  cursor: pointer;
}

.actionButton:hover {
  background: var(--surface-surface_1, #ebebeb);
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.inviteTypeContainer {
  padding-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.inviteTypeList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: min-content;
  margin-top: 12px;
}

.inviteTypeItem {
  display: flex;
  justify-content: center;
  padding: var(--spacing-4, 24px);
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  color: #434343;
  font-weight: 300;
  border-radius: var(--radius-m, 16px);
  border: 1px solid var(--outline-med_em, #EAEAE8);
  text-decoration: none;
}

.inviteTypeItem:disabled {
  background: var(--surface-surface_2, #F2F2F1);
}

.inviteTypeItem:disabled:hover {
  border: 1px solid var(--outline-med_em, #EAEAE8);
  cursor: not-allowed;
}

.inviteTypeItem > div {
  text-align: center;
}

.inviteTypeItem:hover {
  cursor: pointer;
  border-radius: var(--radius-m, 16px);
  border: 1px solid var(--outline-primary_high_em, #8338EC);
  text-decoration: none;
}

.inviteTypeItemActive {
  border-radius: var(--radius-m, 16px);
  border: 1px solid var(--outline-primary_high_em, #8338EC);
  text-decoration: none;
}

.modalTitle {
  color: var(--text-high_em, #0C0C0C);
  font-family: Afacad;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.24px;
}

.modalDescription {
  color: var(--text-med_em, #434343);
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.emailForm {
  padding-top: 20px;
}

.inputText {
  padding: 8px 12px;

  border-radius: var(--radius-border_radius_12, 12px);
  border: 0px solid var(--outline-med_em, #EAEAE8);
  background: var(--surface-surface_2, #F2F2F1);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.inputTextarea {
  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  border-radius: var(--radius-border_radius_12, 12px);
  border: 0px solid var(--outline-med_em, #EAEAE8);
  background: var(--surface-surface_2, #F2F2F1);
  min-height: 200px;
  margin-top: 8px;
}

.inviteButton {
  padding: 8px 16px;
  border-radius: var(--radius-border_radius_12, 12px);
  border: 0px solid var(--outline-med_em, #EAEAE8);
  background: var(--surface-surface_0, #292929);
  color: #FFF;
  cursor: pointer;
}

.inviteButton:disabled {
  background: var(--surface-surface_2, #F2F2F1);
  cursor: not-allowed;
}

.inviteButtonContainer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.errorMessage {
  color: var(--text-error_em, #FF0000);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.uploadCsvSubtitle {
  color: var(--text-med_em, #434343);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 24px;
}

.uploadCsvFileName {
  color: var(--text-high_em, #0C0C0C);
  border-radius: var(--radius-border_radius_12, 12px);
  border: 1px solid var(--outline-med_em, #EAEAE8);

  font-family: Afacad;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  text-align: center;
  padding: 20px;
  margin: 10px 0;
}

.grid_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.inviteDisabledInfo {
  padding: 12px;
  border-radius: 12px;
  border: 1px solid var(--outline-med_em, #EAEAE8);
  background-color: #f8f8f8;
  margin-bottom: 12px;
}

.emailInviteListLabel {
  text-align: center;
  font-weight: 600;
}

.rejectFormContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.paddingContent {
  padding-left: 32px;
  padding-right: 32px;
}
