.sidebarContainer {
  display: flex;
  flex-direction: row;
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.subscriptionInfoContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sectionTitle {
  font-size: 20px;
  line-height: 24px;
  width: 700;
  margin-top: 32px;
  margin-bottom: 16px;
  color: #1c1c1c;
  font-weight: 700;
}

.info,
.errorInfo {
  max-width: 400px;
  margin: 0;
}

.errorInfo {
  color: red;
}
