.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0c0c0c;
  padding-bottom: 8px;
}

.grid2 {
  grid-column: span 2;
}

.btnContent {
  display: flex;
  gap: 8px;
}

.textCenter {
  text-align: center;
}
