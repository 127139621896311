@import '../../../styles/marketplace.css';

.toolbar {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.menu {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorDark);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 0px 24px;
  position: relative;
  width: auto;
}

.menuIcon {
  margin: 9px 0;
}

.textWhite {
  color: #FFF;
}

.textDark {
  color: var(--matterColorDark);
}

.textDarkLink {
  color: #656565;
}

.container {
  /* Size */
  width: 100%;
  height: 88px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (--viewportMedium) {
    display: none;
  }
}

.bgWhite {
  background-color: var(--matterColorLight);
}

.bgDark {
  background-color: #292929;
}

.logo {
  width: 127px;
  height: auto;
}

.topbarLeftContainer {
  display: flex;
  align-items: flex-start;
}

.mobileTopbarButtonContainer {
  display: flex;
  align-items: center;
  padding-right: 24px;
  gap: 16px;
}

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.contentBasic {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 200px;
  margin-top: 50px;
  padding: 10px;
}

.navigationLink {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  white-space: nowrap;
  transition: 0.2s;
  width: 100%;

  /* Font */
  /* @apply --marketplaceTabNavFontStyles; */

  padding: 10px 12px;

  /* push tabs against bottom of tab bar */
  margin-top: 0;
  margin-bottom: 8px;
  border-radius: 8px;
  transition: var(--transitionStyleButton);
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: #1c1c1c;
    background-color: #eff6fb;
  }

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }

  cursor: pointer;
}

.searchContainer {
  margin: 20px 0px;
  border-radius: 40px;
  border: 1px solid #ddd;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  width: 100%;
}

.searchContainer form {
  flex: 1;
}

.searchContainer input {
  padding: 0;
  border-bottom: none;
}

.profileLink {
  margin-right: 24px;
}

.btnLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 36px;
  padding: 8px 16px;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 1px solid var(--surface-white, #292929);
  background-color: #fff;

  color: var(--surface-white, #292929);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  @media (--viewportMedium) {
    width: fit-content;
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
  }
}

.btnProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 36px;
  padding: 8px 16px;

  border-radius: var(--radius-border_radius_64, 64px);
  border: 1px solid var(--surface-white, #292929);
  background-color: #fff;

  color: var(--surface-white, #292929);
  font-family: Afacad;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  @media (--viewportMedium) {
    width: fit-content;
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
  }
}
